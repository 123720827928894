import { updateCouponPool } from 'actions/couponPools'
import { alert } from 'actions/flash'
import { updateRedeemTemplate } from 'actions/redeemTemplates'
import useDispatch from 'hooks/useDispatch'
import useHiddenMenus from 'hooks/useHiddenMenus'
// eslint-disable-next-line no-restricted-imports
import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import {
  CouponPoolGenerationType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { Fields as PointsExperience } from 'models/PointsExperience'
import {
  Fields as RedeemTemplate,
  RedemptionVenue,
} from 'models/RedeemTemplate'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import { getRedeemTemplateType, isNDR } from '../helpers'
import { FormModel } from './FormModel'
import useCouponPools from './useCouponPools'

type SaveFn = (model: FormModel) => Promise<void>
interface Result {
  triggerSave: DebouncedFunc<SaveFn>
  forceSave: SaveFn
  isSaving: boolean
  error: string | null
}

export default function (templateId: number | null): Result {
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()

  const { createCouponPool, getCouponPools } = useCouponPools()
  let templateCouponPools: CouponPool[] | undefined
  const pointsExperience: PointsExperience | null =
    useSelector(selectExperience)
  const { categories } = useHiddenMenus()

  const saveTemplate = useCallback(
    async (formModel: FormModel): Promise<void> => {
      // TODO: bailout if nothing's changed
      const {
        typeItem,
        name,
        instoreRedemptionType,
        description,
        discount,
        perceivedValue,
        availableOnline,
        availableInstore,
        availableCustomerSupport,
        discountedProductIds,
        requiredProductIds,
        instoreDiscountedProductIds,
        onlineDiscountedProductIds,
        instoreRequiredProductIds,
        onlineRequiredProductIds,
        hiddenMenuKey,
        url,
        longDescription,
        numberOfPoints,
        selectedCouponPools,
      } = formModel
      if (!templateId || !typeItem) {
        return
      }
      templateCouponPools = await getCouponPools(
        templateId,
        formModel?.couponCodes?.generationType
      )

      let redemptionVenue: RedemptionVenue = 'all'
      if (typeItem?.key === 'golden_ticket') {
        redemptionVenue = 'instore'
      } else if (!availableOnline) {
        redemptionVenue = 'instore'
      } else if (!availableInstore) {
        redemptionVenue = 'online'
      }
      const templateType = getRedeemTemplateType(
        typeItem,
        instoreRedemptionType
      )
      const { key, subtype } = typeItem
      const isHiddenMenu = key === 'hidden_menu'
      const isExperience = isHiddenMenu || key === 'golden_ticket'
      const isBonusPoints = key === 'bonus_points'

      // set maximum field to 250 for auto cashback
      // and purchase templates.
      let maximum: number | null = null
      if (
        templateType === 'automatic/percent' &&
        (subtype === 'purchase' || subtype === 'bogo/purchase')
      ) {
        maximum = 250
      }

      const allowCouponPools = !isNDR(templateType)

      const params: Partial<RedeemTemplate> = {
        type: templateType,
        subtype,
        name,
        description,
        discount: Number(discount) || undefined,
        perceived_value: Number(perceivedValue) || undefined,
        redemption_venue: redemptionVenue,
        granted_program_visible: availableCustomerSupport && !isExperience,
        discounted_product_ids: discountedProductIds.length
          ? discountedProductIds
          : undefined,
        required_product_ids: requiredProductIds.length
          ? requiredProductIds
          : undefined,
        maximum: maximum ?? undefined,
        url: url ?? undefined,
        long_description: longDescription,
      }

      if (allowCouponPools) {
        params.coupon_pool_ids = []
        if (instoreRedemptionType === 'manager_comp') {
          // if user selects manager_comp, we must clear
          // any assigned coupon pool and re-fetch template pools
          params.coupon_pool_ids = []
        } else if (
          formModel?.couponCodes?.generationType ===
            CouponPoolGenerationType.STATIC &&
          !!templateCouponPools?.length
        ) {
          params.coupon_pool_ids = [templateCouponPools[0].id]
        } else if (!!selectedCouponPools?.length) {
          let couponPoolIds: number[] = []
          couponPoolIds = selectedCouponPools.map(s => s.id)
          params.coupon_pool_ids = couponPoolIds
        }
      }

      // update product_ids only for manual redemptions
      if (
        templateType === 'manual/item' ||
        templateType === 'manual/percent' ||
        templateType === 'manual/amount' ||
        subtype === 'bogo/purchase'
      ) {
        params.product_ids = {
          instore: {
            discounted: instoreDiscountedProductIds.length
              ? instoreDiscountedProductIds
              : undefined,
            required: instoreRequiredProductIds.length
              ? instoreRequiredProductIds
              : undefined,
          },
          digital: {
            discounted: onlineDiscountedProductIds.length
              ? onlineDiscountedProductIds
              : undefined,
            required: onlineRequiredProductIds.length
              ? onlineRequiredProductIds
              : undefined,
          },
        }
      }

      if (isExperience) params.granted_program_visible = false
      if (isHiddenMenu) {
        const hiddenMenu = hiddenMenuKey
          ? categories.find(hm => hm.ids.join(',') === hiddenMenuKey[0])
          : null
        if (hiddenMenu) {
          params.unhides_category_ids = hiddenMenu.ids
          params.category_name = hiddenMenu.name
        } else {
          if (typeof hiddenMenuKey === 'string') {
            params.unhides_category_ids = hiddenMenuKey
              .split(',')
              .map(key => Number(key))
          }
        }
      }
      if (isBonusPoints && numberOfPoints) {
        params.number_of_points = Number(numberOfPoints)
        params.points_experience_id = pointsExperience?.id
      }
      const response = await dispatch(updateRedeemTemplate(templateId, params))
      if (!params.coupon_pool_ids?.length) {
        await getCouponPools(templateId)
      }

      if (response?.error) {
        dispatch(
          alert({
            key: 'danger',
            //@ts-ignore
            message: response?.error?.response?.data?.errors?.messages,
            timeout: 5,
          })
        )
      }
      setIsSaving(false)
    },
    [categories, templateId, dispatch, pointsExperience]
  )

  const saveCouponPool = useCallback(
    async (formModel: FormModel): Promise<void> => {
      if (!templateId) return
      let couponPool: CouponPool | undefined
      const {
        generationType: formGenerationType,
        type,
        staticCode,
      } = formModel.couponCodes
      const generationType =
        formGenerationType ?? CouponPoolGenerationType.DYNAMIC
      templateCouponPools = await getCouponPools(templateId)
      const couponPools =
        templateCouponPools?.filter(
          c => c.generation_type === generationType
        ) || []

      // We save the values only for static and dynamic generation types
      // for variable we'll always create a new CP for each upload.
      if (generationType === CouponPoolGenerationType.VARIABLE) {
        return
      }

      if (
        formModel.instoreRedemptionType === 'coupon_codes' &&
        !couponPools?.length &&
        !isEmpty(staticCode)
      ) {
        // in case we don't have a pool selected in a static code
        // and we have some code entered, we should create
        // a coupon pool which will be used from now on.
        couponPool = await createCouponPool({
          templateId,
          staticCode,
          generationType,
        })
      } else if (!!couponPools?.length) {
        couponPool = couponPools[0]
      }

      if (!couponPool) return

      // TC-3818: couponPool.static_code can take empty string or null values.
      // So we may skip updating the coupon pool when both the form and backend
      // has an empty static_code.
      // const isEmptyCodes = isEmpty(staticCode) && isEmpty(couponPool.static_code)
      const isSameStaticCode =
        staticCode === couponPool.static_code || isEmpty(staticCode)
      // Bail out if there's nothing to update
      if (
        generationType === couponPool.generation_type &&
        type === couponPool.type &&
        isSameStaticCode
      ) {
        return
      }

      const payload = {
        generation_type: generationType,
        type: type,
        static_code: staticCode,
      }
      await dispatch(updateCouponPool(templateId, couponPool.id, payload))
      await getCouponPools(templateId)
    },
    [templateId, dispatch]
  )

  const save = useCallback(
    async (formModel: FormModel) => {
      setIsSaving(true)
      // we must save the template first, so we're confident about
      // assigned / unassigned pools before saving the coupon pool
      await saveTemplate(formModel)
      await saveCouponPool(formModel)
      setIsSaving(false)
    },
    [saveTemplate, saveCouponPool]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerSave = useCallback(debounce(save, 1000), [save])

  return {
    triggerSave,
    forceSave: save,
    isSaving,
    error: null,
  }
}
