import { MerchantUser, Role } from 'api/merchantUsers'
import React from 'react'
import { useStyletron } from 'styletron-react'
import LocationList from './LocationList'
import RolesCard from './RolesCard'

type Props = {
  merchantUser: Partial<MerchantUser>
  onChange: (merchantUser: Partial<MerchantUser>) => void
}

const MerchantUserForm = ({ merchantUser, onChange }: Props) => {
  const [css] = useStyletron()
  return (
    <div className={css({ display: 'flex', width: '100%' })}>
      <div className={css({ width: '100%', marginRight: '40px' })}>
        <RolesCard
          role={merchantUser.role || Role.admin}
          onChange={role => onChange({ ...merchantUser, role })}
        />
      </div>
      <div className={css({ width: '100%' })}>
        <LocationList
          role={merchantUser.role || Role.admin}
          onChange={location_ids => {
            onChange({ ...merchantUser, location_ids })
          }}
        />
      </div>
    </div>
  )
}

export default MerchantUserForm
