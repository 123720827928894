import { useStyletron } from '@thanx/uikit/theme'
import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router'
import CreateEdit from './CreateEdit'
import Landing from './Landing'
import Multipliers from './Multipliers'
import PointsExperienceEdit from './PointsExperience/Edit'

const Points: React.FC = () => {
  //@ts-ignore
  const routing = useSelector(state => state.routing)
  const pathname = routing?.location?.pathname || ''
  const editMatch = pathname.match(/points\/(.*?)\/edit/)
  const [css] = useStyletron()
  const background = editMatch ? 'white-bkg' : 'grey-05-bkg'

  return (
    <div
      className={`${css({
        minHeight: editMatch ? 'calc(100vh - 80px)' : '100vh',
      })} ${background}`}
    >
      <Switch>
        <ProtectedRoute
          redirect="/thanx_campaigns"
          accessType="access"
          resource="Points"
          exact
          path="/points"
          component={Landing}
        />
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="Points"
          path="/points/experiences/:id/multipliers"
          component={Multipliers}
        />
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="Points"
          exact
          path="/points/experiences/:id/:section/edit"
          component={PointsExperienceEdit}
        />
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="Points"
          exact
          path="/points/create"
          component={CreateEdit}
        />
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="Points"
          exact
          path="/points/:id/edit"
          component={CreateEdit}
        />
      </Switch>
    </div>
  )
}

export default Points
