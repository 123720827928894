import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import ToS from './ToS'

type Props = {
  image?: string
}

const t = buildTranslate('points.configuration.instructions')

const ImageInstructions: React.FC<Props> = ({ image }) => {
  const [css] = useStyletron()
  if (!image) return null
  return (
    <div>
      <Text.BodyText4 bold tag="div" className="mb-m">
        {t('show_image_title')}
      </Text.BodyText4>
      <img src={image} alt="earn" width={376} />
      <div
        className={`${css({
          width: '376px',
          height: '68px',
          backgroundColor: defaultTheme.colors.grey10,
        })} d-flex align-items-center`}
      >
        <ToS className="m-auto" />
      </div>
    </div>
  )
}

export default ImageInstructions
