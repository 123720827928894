import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { Col } from 'react-bootstrap-five'
import { Link } from 'react-router-dom'

type ButtonT = {
  className: string
  text: string
  icon: string
  url: string
}

const Button: React.FC<ButtonT> = ({ className, icon, url, text }) => {
  const [css] = useStyletron()

  return (
    <Col className={`mb-xs ${className}`} md={true} sm={12}>
      <Link
        className={css({
          textDecoration: 'none !important',
        })}
        to={url}
      >
        <button
          className={`w-100 p-m white-bkg text-left d-flex align-content-center ${css(
            {
              borderRadius: '4px',
              borderWidth: '1px',
              boxShadow: 'none',
              borderStyle: 'solid',
              transition: 'border-color 0.2s ease',
              borderColor: defaultTheme.colors.grey30,
              ':hover': {
                borderColor: defaultTheme.colors.grey90,
              },
            }
          )}`}
        >
          <img className="mr-s" src={icon} alt={text} />
          <Text.BodyText3>{text}</Text.BodyText3>
        </button>
      </Link>
    </Col>
  )
}

export default Button
