import { Text } from '@thanx/uikit/text'
import { legalApi } from 'api/legal'
import { buildTranslate } from 'locales'
import LegalContainer from './common/LegalContainer'

const t = buildTranslate('legal.participating_merchants')

function ParticipatingMerchants() {
  const { data, isLoading } = legalApi.useGetLegalMerchantsQuery()
  const merchants = data?.merchants || []

  return (
    <LegalContainer isLoading={isLoading}>
      {merchants.length > 0 && (
        <>
          <Text.Header2>{t('title')}</Text.Header2>

          {merchants.map(merchant => (
            <Text.BodyText3 tag="p" key={merchant.name}>
              {merchant.name}
            </Text.BodyText3>
          ))}
        </>
      )}
    </LegalContainer>
  )
}

export default ParticipatingMerchants
