import { Button, Kind, Size } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Help from 'components/Help'
import PopoverContent from 'components/PopoverContent'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { push } from 'react-router-redux'
import { helpArticles } from './helpers'

type Props = {
  canEdit: boolean
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration.expiration')
const ht = buildTranslate('points.configuration.help')

const Expiration: React.FC<Props> = ({ canEdit, pointsExperience }) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const { id, points_expires_in_months } = pointsExperience

  return (
    <section className="grey-90">
      <Text.Header4 tag="div" className="mb-xl">
        {t('title')}
      </Text.Header4>
      <div className="card p-m white-bkg">
        <div className="d-flex">
          <Text.BodyText4 bold tag="div" className="mb-m flex-1">
            {t('when_points_expire')}
          </Text.BodyText4>
          {canEdit && (
            <Button
              kind={Kind.SECONDARY}
              size={Size.SMALL}
              className={css({ height: '32px' })}
              onClick={() =>
                dispatch(push(`/points/experiences/${id}/expiration/edit`))
              }
            >
              {t('edit')}
            </Button>
          )}
        </div>
        <Text.BodyText3 color="grey70">
          {points_expires_in_months} {t('months_after_purchase')}
          <span className="ml-xs">
            <PopoverContent
              sections={[
                { content: t('expire_popover_description_1') },
                { content: t('expire_popover_description_2') },
              ]}
            />
          </span>
        </Text.BodyText3>
      </div>

      <Help title={ht('title')} articles={helpArticles.expiration} />
    </section>
  )
}

export default Expiration
