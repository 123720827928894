import { RESET_DB } from 'actions/orm'
import { GET_USER_EVENTS_SUCCESS, ResolvedAction } from 'actions/users'
import { Model, ModelType } from 'redux-orm'
import { Fields as UserReward } from './UserReward'

type EventType =
  | 'reward_intro_delivered'
  | 'reward_ongoing_delivered'
  | 'reward_vip_delivered'
  | 'reward_used'
  | 'reward_purchased'
  | 'purchased'
  | 'transacted'
  | 'invitation_progress_granted'
  | 'merchant_progress_granted'
  | 'progress_provision_applied'
  | 'points_earned'
  | 'points_returned'
  | 'points_exchanged'
  | 'points_refunded'
  | 'points_expired'
  | 'points_expired_revers'

type BaseFields = {
  id: number
  user_id: number
  occurred_at: string
  title: string
  location_id: number
  location_address: string
  related_id: number
}

interface RewardEvent extends BaseFields {
  type: Extract<
    EventType,
    | 'reward_intro_delivered'
    | 'reward_ongoing_delivered'
    | 'reward_vip_delivered'
    | 'reward_used'
    | 'reward_purchased'
  >
  related_item: UserReward
}

export interface TransactedEvent extends BaseFields {
  type: Extract<EventType, 'transacted'>
  related_item: { granted: boolean }
}

interface StandardEvent extends BaseFields {
  type: Exclude<
    EventType,
    | 'reward_intro_delivered'
    | 'reward_ongoing_delivered'
    | 'reward_vip_delivered'
    | 'reward_used'
    | 'reward_purchased'
    | 'transacted'
  >
  related_item: null
}

export type Fields = RewardEvent | TransactedEvent | StandardEvent

// @ts-ignore
export default class UserEvent extends Model<typeof UserEvent, Fields> {
  static modelName: string = 'UserEvent'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserEvent: ModelType<UserEvent>
  ): void {
    switch (action.type) {
      case GET_USER_EVENTS_SUCCESS:
        action.payload.data.events.forEach(event => UserEvent.upsert(event))
        break
      case RESET_DB:
        UserEvent.all().delete()
        break
      default:
        break
    }
  }
}
