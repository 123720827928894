import brand from './brand'
import content from './content'
import settings from './settings'
import signup from './signup'

export default {
  landing: {
    title: 'Brand & Content',
    tabs: {
      content: 'content',
      brand: 'brand',
      settings: 'settings',
      signup: 'sign-up',
      home_page: 'Home page',
    },
    not_configured: {
      title: 'Branded app and Thanx Ordering are not configured',
      description:
        'Go beyond the Thanx app with your own branded app. Instagram-worthy images, custom home page layouts, custom colors, and a listing in the app store tell the story of your brand and your brand only.<br/><br/>Then, take things a step further with Thanx Ordering. Supercharge your customer database with Thanx-powered digital ordering integrations. Thanx offers a variety of ways to integrate, whether it’s working with your current setup or creating a seamless transition to our native ordering experience. We offer robust app and web configurations to best suit your needs.',
      contact:
        'Contact our team to learn more about branded apps and Thanx Ordering',
      image_alt: 'Branded apps and Thanx Ordering',
    },
  },
  brand,
  content,
  settings,
  signup,
  components: {
    blocks: {
      add_new: 'Add new %{type}',
      delete: 'Delete %{type}',
      move_up: 'Move %{type} %{index} up',
      move_down: 'Move %{type} %{index} down',
      delete_modal: {
        title: 'Delete %{type}',
        description:
          'After deleting this %{type}, you will still need to publish your changes to save them and make them live in the app.',
        confirm: 'Delete %{type}',
      },
    },
    image_picker: {
      add_image: 'add image',
      change: 'Change',
      low_quality_modal: {
        title: 'This is a low quality image',
        description:
          'The image you are trying to upload is smaller than recommended. Proceeding may result in pixelated, blurry, or stretched images.',
        confirm: 'Continue uploading',
      },
      error: 'Image could not be uploaded. Please try again.',
      file_too_large:
        'The file could not be uploaded because the file size of %{size}MB is too large. Images must be 3MB or smaller.',
      image_smaller:
        'Image is smaller than recommended and may result in poor quality',
      upload_progress: {
        reading_file: 'Reading file',
        uploading: 'Uploading',
        percentage: '%{value}%',
        please_wait: 'Please wait for your image to finish processing.',
        processing: 'Processing',
      },
      delete: 'Delete image',
      preview: 'View file',
    },
    links: {
      title_name: 'Title',
      type: 'Type',
      authenticable: 'Authenticable',
      url: 'URL',
      yes: 'Yes',
      no: 'No',
    },
    publish_banner: {
      ready_go_live: 'Ready to go live?',
      hit_publish:
        'Hit the “Publish” button to save changes and push them to your app or website.',
    },
    draft_banner: {
      title: 'This page is in draft mode.',
      description: 'Contact us to push this page live to your app.',
      contact_us: 'Contact us',
      save_draft: 'Save draft',
    },
    url_picker: {
      label: 'Where should this %{type} link to?',
      url: 'URL',
      page_within: 'Page within the app',
      no_link: 'Don’t link to anything',
      about: 'About',
      account: 'Account',
      locations: 'Locations',
      nps: 'NPS',
      notifications: 'Notifications',
      registered_cards: 'Registered cards',
      rewards: 'Rewards',
      support: 'Support',
      menu: 'Ordering menu (first category only)',
      menu_alt: 'Ordering menu',
      history: 'Order history',
      shop: 'Shop',
      remove_link: 'Remove link',
      advanced: 'Advanced',
      advanced_link_disabled:
        "If the experience you're linking to has login powered by Thanx Single Sign On, we're able to log customers into that experience just by clicking a link. <a href='mailto:merchant.support@thanx.com'>Contact our team to make this a log-in link</a>",
      advanced_link_enabled:
        "This link can log customers into an experience powered by Thanx Single Sign On. It is no longer editable. <b>By removing this, customers will no longer be automatically logged in</b> via Thanx’s Single Sign On. <a href='mailto:merchant.support@thanx.com'>Contact us</a>",
    },
    explainer_banner: {
      explainer: 'Explainer video',
      article: 'Help article',
    },
  },
}
