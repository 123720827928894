import { PLACEMENT } from '@thanx/uikit/popover'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import PopoverInfo from 'components/PopoverInfo'
import React from 'react'

type Section = {
  title?: string
  content: string | React.ReactNode
}

export type Sections = Array<Section>

type PropsT = {
  placement?: keyof PLACEMENT
  sections: Sections
}

const PopoverContent: React.FC<PropsT> = props => {
  const { sections, placement } = props

  return (
    <PopoverInfo
      placement={placement}
      text={
        <Text.BodyText5 tag="p" className="mt-m mb-0" color="grey70">
          {sections.map((section, i) => {
            const { title, content } = section
            return (
              <React.Fragment key={i}>
                <Switch condition={!!title}>
                  <Text.SmallCaps3
                    tag="p"
                    bold
                    color="inherit"
                    className="mt-s"
                  >
                    {title}
                  </Text.SmallCaps3>
                </Switch>
                <p>{content}</p>
              </React.Fragment>
            )
          })}
        </Text.BodyText5>
      }
    />
  )
}

export default PopoverContent
