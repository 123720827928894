import { Text } from '@thanx/uikit/text'
import { MerchantUser, merchantUsersApi } from 'api/merchantUsers'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  visible: boolean
  setIsVisible: React.Dispatch<boolean>
  merchantUser?: MerchantUser
}

const t = buildTranslate('merchant_users.delete_modal')

const DeleteModal = ({ visible, setIsVisible, merchantUser }: Props) => {
  const [deleteMerchantUser] = merchantUsersApi.useDeleteMerchantUserMutation()

  if (!merchantUser) {
    return null
  }

  return (
    <Modal
      isOpen={visible}
      onClose={() => setIsVisible(false)}
      proceedText={t('remove')}
      onProceed={() => {
        deleteMerchantUser(merchantUser.id)
        setIsVisible(false)
      }}
    >
      <h3 className="mt-0 mb-xs extra-bold">{t('title')}</h3>
      <div className="mt-xl">
        <div className="d-flex flex-column align-items-start">
          <Text.BodyText2 className="mb-xs">
            {t('description', {
              userName: `${merchantUser?.first_name} ${merchantUser?.last_name}`,
            })}
          </Text.BodyText2>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
