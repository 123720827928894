import { FormsyInput } from '@thanx/uikit/input'
import { Switch } from '@thanx/uikit/switch'
import TooltipLabel from 'components/TooltipLabel'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { useFormModel } from '../FormModel'
import Card from './Card'
import NextButton from './NextButton'

type Props = {
  isOpen: boolean
  isCurrent: boolean
  isDisabled: boolean
  isValidForm: boolean
  onNext: () => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.item_value_card'
)

const ItemValueCard: React.FC<Props> = ({
  isOpen,
  isCurrent,
  isDisabled,
  isValidForm,
  onNext,
}) => {
  const { discount, perceivedValue, typeItem } = useFormModel().model
  const types = typeItem ? typeItem.types : []

  const isGoldenTicket = types.includes('experience/golden_ticket')
  const validations = isGoldenTicket
    ? {
        isNumeric: true,
        greaterThan: -1,
      }
    : {
        greaterThan: 0,
        isRequired: true,
        isNumeric: true,
      }

  const hasData633Feature = useFlag('DATA-633', false)
  const businessCostTip = hasData633Feature
    ? t('cost_to_business_info_v2')
    : t('cost_to_business_info')

  return (
    <Card title={t('title')} isOpen={isOpen}>
      <p className="grey-70 mt-n3 mb-m">{t('subtitle')}</p>
      <div className="d-flex">
        <FormsyInput
          // @ts-ignore
          className="width-50 mr-m"
          name="discount"
          disabled={isDisabled}
          label={
            <TooltipLabel
              label={t('cost_to_business_label')}
              tooltip={businessCostTip}
            />
          }
          startEnhancer={t('dollar')}
          value={discount}
          validations={validations}
          validationErrors={{
            greaterThan: I18n.t('validations.greater_than', { value: 0 }),
            isRequired: I18n.t('validations.is_required'),
            isNumeric: I18n.t('validations.is_numeric'),
          }}
        />
        <FormsyInput
          // @ts-ignore
          className="width-50"
          name="perceivedValue"
          disabled={isDisabled}
          label={
            <TooltipLabel
              label={t('retail_price_label')}
              tooltip={t('retail_price_info')}
            />
          }
          startEnhancer={t('dollar')}
          value={perceivedValue}
          validations={validations}
          validationErrors={{
            greaterThan: I18n.t('validations.greater_than', { value: 0 }),
            isRequired: I18n.t('validations.is_required'),
            isNumeric: I18n.t('validations.is_numeric'),
          }}
        />
      </div>
      <Switch condition={isCurrent}>
        <NextButton disabled={!isValidForm} onClick={onNext} />
      </Switch>
    </Card>
  )
}

export default ItemValueCard
