import { cmsUrls } from 'utilities/urlUtils'

export default {
  app_home_page: {
    tab_title: 'App home page',
    submit_error: 'This content block could not be saved. Please try again.',
    validation_error: 'Check the form for errors and try again.',
    submit_success: 'Your changes to your App home page have been saved.',
    sidebar: {
      publish: 'Publish home page',
      preview: 'Content Preview',
    },
    footer: {
      publish: 'Publish home page',
    },
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>App home page</strong><br/>Publishing these changes will push them live to your app.',
      publish: 'Yes, publish changes',
    },
    style_settings: {
      subtab_title: 'Style',
      title: 'App home page - Style',
      description: 'Update the colors of your home page to match your brand.',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      link_color: 'Link color',
    },
    content: {
      subtab_title: 'Content',
      title: 'App home page - Content',
      description: `Your app's home page. You can customize this page to match your marketing site, promote content and more. <a href='${cmsUrls.brandedHomepage}' target='_blank'>See examples</a>`,
      empty_state: {
        title: 'Your app’s home page doesn’t have any content',
        description:
          'Use this page to tell customers about your menu, share your brand values, promote your loyalty program, and more. Click the “Add a new block” button below to get started.',
      },
      block_item: {
        edit: 'Edit',
        already_added: 'Already added',
        add_block: 'Add block',
        add_content: 'Add content',
        contact_us: 'Contact us',
        featured_content: {
          title: 'Custom Image',
          link_description: 'Where should this image link to?',
          description:
            'A multi-purpose block that you can use to upload a custom image.',
          no_link: 'Don’t link to anything',
          page_within_app: 'Page within the app:',
          external_url: 'URL:',
          ordering_menu: 'Ordering menu:',
          no_category_selected: 'First menu category',
        },
        missing_loyalty_prompt: {
          title: 'Transferred account confirmation',
          description:
            'Customers that transferred their account from your previous loyalty program see a confirmation that their loyalty perks transferred. They are encouraged to contact support if something is not right with their new account.',
        },
        stored_value_balance: {
          title: 'Manage balance',
          description:
            'Customers see the balance available in their digital wallet (stored value). They can tap on this component to go directly to a page where they can manage their balance.',
        },
        rewards: {
          title: 'Reward carousel',
          description:
            'Customers see which rewards are available to them as a horizontal carousel on the app’s home page.',
        },
        recent_orders: {
          title: 'Recent orders carousel',
          description:
            'Customers see their recent order as a horizontal carousel on the app’s home page.',
        },
        menu_categories: {
          title: 'Menu categories carousel',
          description:
            'Customers see menu categories as a horizontal carousel on the app’s home page.',
        },
        external_form: {
          title: 'Capture custom data',
          description:
            'Connect your app to a custom form (e.g., Jotform) and collect more information about your customers (e.g., Birthdays, Anniversaries, etc.)',
          unavailable:
            'To set up a form, reach out to us with your published form’s name and URL. We will let you know when your new form is connected and ready to use with this content block.',
          form_description: 'Which form this block links to',
          no_form_id: 'No form linked',
          email_subject: 'I would like to add a custom form',
        },
      },
      edit_block: {
        save: 'Save & return to CMS',
        delete: {
          button: 'Remove block',
          modal: {
            title: 'Remove this block',
            description:
              'Customers will no longer see this block on your app’s home page as soon as you click the Publish button after removing this block.',
            confirm: 'Yes, remove block',
          },
        },
        cancel: 'Cancel',
        tabs: {
          content: 'Content',
          style: 'Style',
        },
        content: {
          title: 'Content',
          badges: {
            app_only: 'app only',
            wide_1800_px: '1800 px wide',
            any_height: 'any height',
            jpg: 'jpg',
            png: 'png',
          },
          helper_section: {
            help: 'Help',
            configure: 'How to configure custom forms to capture customer data',
          },
          external_form: {
            select_a_form: 'Select a form',
            form_description: 'Which form this block links to',
            description_1:
              'Connect your app to a custom form (e.g., Jotform) and collect more information about your customers (e.g., Birthdays, Anniversaries, etc.).',
            description_2:
              'Select an existing form from the list above. If you don’t have any external forms configured or if you want to add a new form, reach out to us with your published form’s name, and URL. We will let you know when your new form is connected and ready to use with this content block.',
            contact_us: 'Contact us',
            email_subject: 'I would like to add a custom form',
          },
          featured_content: {
            first_menu_category: 'First menu category',
            menu_category_selector: 'Which menu category should this link to?',
            menu_category_disclaimer:
              'This block will be visible only if the menu category is available for the customer’s selected location. e.g. Burgers are available at Location A but not Location B. A customer shopping at Location A will see this block. A customer shopping at location B will not see this block.',
            eye_icon: 'eye',
          },
        },
        style: {
          title: 'Style',
          border: {
            title: 'Border',
            carousel_title: 'Carousel card - Border',
            border_color: 'Border color',
            border_width: 'Border size',
            border_radius: 'Corner radius',
          },
          padding: {
            title: 'Padding',
            carousel_title: 'Carousel - Spacing',
            padding_top: 'Top',
            padding_right: 'Right',
            padding_bottom: 'Bottom',
            padding_left: 'Left',
            item_spacing: 'Space between cards',
          },
          button: {
            title: 'Button',
            carousel_title: 'Carousel card - Button',
            button_text_color: 'Button text color',
            button_bg_color: 'Button color',
            button_border_color: 'Button border color',
            close_icon_color: 'Close icon color',
          },
          content: {
            title: 'Content',
            carousel_title: 'Carousel card - Content',
            background_color: 'Background color',
            header_text_color: 'Heading text color',
            body_text_color: 'Body text color',
            chevron_color: 'Chevron color',
            link_color: 'Link color',
          },
        },
      },
    },
  },
  menu_category: {
    tab_title: 'Menu category pages',
    submit_error: 'This content block could not be saved. Please try again.',
    validation_error: 'Check the form for errors and try again.',
    submit_success: 'Your changes to your App home page have been saved.',
    content: {
      title: 'Menu category pages',
      description:
        'Update the colors and style of your menu category pages to match your brand.',
    },
    sidebar: {
      publish: 'Publish',
      preview: 'Content Preview',
    },
    footer: {
      publish: 'Publish',
    },
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Menu category pages</strong><br/>Publishing these changes will push them live to your app.',
      publish: 'Yes, publish changes',
    },
    page_form: {
      title: 'Page style',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      link_color: 'Link color',
    },
    product_card: {
      title: 'Product card style',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      border_color: 'Border color',
      border_width: 'Border size',
      border_radius: 'Corner radius',
    },
    unavailable_product_card: {
      title: 'Product card style - unavailable items',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      border_color: 'Border color',
    },
  },
  hero_carousel: {
    title: 'Home page: hero carousel',
    tab_title: 'Hero carousel',
    slide: 'slide %{index}',
    publish: 'Publish hero carousel',
    save_draft: 'Save draft',
    description: `The hero carousel on your app’s home page. You can use the images in the carousel to welcome your visitors, feature your latest marketing campaign, showcase products, or promote your brand. <a href='${cmsUrls.brandedHomepage}' target='_blank'>See example</a> or <a href='https://duisjpf052nxj.cloudfront.net/assets/mx/Launch+Screen+template.fig'>download the Figma template</a>.`,
    submit_error: 'This page could not be saved. Please try again.',
    submit_success:
      'Your changes are now live. Refresh your mobile app and/or website to see them.',
    submit_draft_success:
      'Your draft has been saved. Contact us to push this page live.',
    badges: {
      app_only: 'app only',
      size: '1800 x 900px',
      jpg: 'jpg',
      png: 'png',
    },
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Home page: hero carousel</strong><br/>Publishing these changes will push them live to your app and/or website.',
      publish: 'Yes, publish changes',
    },
  },
  content_blocks: {
    title: 'Home page: content blocks',
    tab_title: 'Content blocks',
    description: `The main content on your app‘s home page—it lives below the reward banner. You may add as many content blocks as you like. Configure these blocks to elevate links, promote your brand and connect with your audience. <a href='${cmsUrls.brandedHomepage}' target='_blank'>See example</a> or <a href='https://duisjpf052nxj.cloudfront.net/assets/mx/Launch+Screen+template.fig'>download the Figma template</a>.`,
    page_background_color: 'Page background color',
    color_description: 'This color sits behind your content',
    publish: 'Publish content blocks',
    submit_success:
      'Your changes to your Home page: content blocks are now live. Refresh your mobile app to see them.',
    submit_draft_success:
      'Your draft has been saved. Contact us to push this page live.',
    submit_error: 'This page could not be saved. Please try again.',
    badges: {
      app_only: 'app only',
      wide_1800_px: '1800 px wide',
      jpg: 'jpg',
      png: 'png',
    },
    save_draft: 'Save draft',
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Home page: content blocks</strong><br/>Publishing these changes will push them live to your app and/or website.',
      publish: 'Yes, publish changes',
    },
    block_item: {
      title: 'Content block %{index}',
    },
  },
  fixed_buttons: {
    title: 'Home page: fixed buttons',
    tab_title: 'Fixed buttons',
    description:
      'Your app’s home page can have an optional fixed footer button that sits in front of other content. You can add up to two buttons.',
    publish: 'Publish fixed buttons',
    submit_success:
      'Your changes are now live. Refresh your mobile app to see them.',
    submit_draft_success:
      'Your draft has been saved. Contact us to push this page live.',
    submit_error: 'This page could not be saved. Please try again.',
    block_item: {
      title: 'Footer Button %{index}',
      button_style: 'Button style',
      solid: 'Solid',
      outline: 'Outline',
      button_text: 'Button text',
      where_should: 'Where should this button link to?',
      url: 'URL',
      page_within: 'Page within the app',
    },
    save_draft: 'Save draft',
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Home page: fixed buttons</strong><br/>Publishing these changes will push them live to your app and/or website.',
      publish: 'Yes, publish changes',
    },
  },
  web_hero_image: {
    title: 'Web hero image',
    description:
      'Your web hero image is what customers see when they land on your web ordering experience. Add a beautiful image to inspire your customers. Photos work better than graphics or logos because this image will scale and crop on different devices. You can also add a catchy tagline.',
    badges: {
      banner_size: '1600 x 938 px',
      png: 'png',
    },
    publish: 'Publish web hero',
    banner_image: 'Banner image',
    png: 'png',
    add_image_alt: 'Add Image',
    hero_image_preview: 'Hero image preview',
    add_image: 'Add image',
    banner_message: 'Banner message',
    write_message: 'Write a short message to be displayed over your image',
    delete_image_error: 'Failed to delete image. Please try again.',
    submit_error: 'This page could not be saved. Please try again.',
    submit_success:
      'Your changes to your ordering banner are now live. Refresh your website to see them.',
    not_enabled_page: {
      title: 'Web ordering is not configured',
      description:
        'Thanx Ordering for web is ideal for driving online revenue and capturing purchase data for customers when you don’t want your customers to download an app. Link your customers to your web storefront via email marketing or paid ad placement, and you’re good to go.<br/><br/>Instagram-worthy images put your brand front and center while the sleek interface lets your customers glide through the order process fast. Seamless loyalty integration lets customers earn and redeem rewards with just a few clicks while capturing data and driving return visits.',
      image_alt: 'Example of Thanx Ordering for web',
      contact_our_team:
        'Contact our team to learn more about Thanx Ordering for web',
    },
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Web hero image</strong><br/>Publishing these changes will push them live to your website.',
      publish: 'Yes, publish changes',
    },
  },
  custom_links: {
    title: 'Custom links',
    description:
      'Your links appear on the rewards page of your app. Configure these links to drive customers to your site or to another part of the app.<br /><br />Need to update your links? <a href="mailto:merchant.support@thanx.com">Contact us</a>',
    see_example: 'See example',
    rewards_links: {
      title: 'Rewards page links',
      description:
        'Customers see these links at the bottom of the rewards page',
      see_example: 'See example',
    },
    premium_links: {
      title: 'Premium links',
      description:
        'Your premium links live at the bottom of the rewards page. You can configure these links with images and text to set them apart.',
      cover_image: 'Cover image',
      title_name: 'Title',
      banner_text: 'Banner text',
      button_text: 'Button text',
      subtitle: 'Subtitle',
      see_example: 'See example',
    },
  },
  exclusive_deals: {
    title: 'Exclusive Deals',
    description:
      'Your customers can purchase pre-packaged offers that can be enabled immediately, directly within the app. Payment is facilitated by Stripe, and you have direct access to revenue generated by your deals. This is a quick and easy way to offer stored value directly in your mobile app.<br /><br />Want to configure Exclusive Deals? <a href="mailto:merchant.support@thanx.com">Contact us</a>',
    view_performance: 'View performance',
    item: {
      cover_image: 'Cover image',
      title_label: 'Title',
      description_label: 'Description',
      price_label: 'Price',
      quantity_label: 'Quantity',
      long_description_label: 'Long description',
      quantity_tooltip: 'Customers will receive this deal as this many rewards',
    },
    empty_page: {
      title: 'Configure Exclusive Deals',
      description:
        'A quick and easy way to offer stored value directly in your mobile app. Your customers can purchase pre-packaged offers that can be enabled immediately, directly within the app. Payment is facilitated by Stripe, and you have direct access to revenue generated by your deals.',
      image_alt: 'Exclusive Deals',
      contact_our_team: 'Contact our team to turn on Exclusive Deals',
    },
  },
  alert_banner: {
    title: 'Alert banner',
    description: `A banner that’s pinned to the top navigation in your app and/or web ordering experience. Optionally, you may add a button in our outline style. <a href='${cmsUrls.bannerAlert}' target='_blank'>See example</a><br/><br/>If you have ordering enabled the banner applies to all locations. Location specific messages can only be configured with your ordering provider.`,
    app_web: 'App & Web',
    submit_error: 'This page could not be saved. Please try again.',
    submit_success:
      'Your changes to Alert banner are now live. Refresh your mobile app and/or website to see them.',
    banner_visibility: 'Banner visibility',
    make_visible: 'Make banner visible to customers',
    visibility_description:
      'If you have ordering enabled, checking this box will override any banners configured with your ordering provider. Once published, your banner will be visible to all customers.',
    banner_color: 'Banner color',
    color_description: 'This banner defaults to your secondary color.',
    text_color: 'Text color',
    banner_body: 'Banner body (required)',
    banner_title: 'Banner title',
    button_text: 'Button text',
    button_url: 'Button URL',
    allow_customers: 'Allow customers to dismiss banner',
    allow_description:
      'Customers will be able to see a button that lets them hide the banner.',
    yes: 'Yes',
    no: 'No',
    publish_alert: 'Publish alert banner',
    how_it_looks_app: 'How it looks in the app',
    how_it_looks_desktop: 'How it looks on desktop',
    mobile_preview: 'Alert banner mobile preview',
    web_preview: 'Alert banner web preview',
    body_required: 'Banner body is required',
    max_length_body: 'Max length is 130',
    max_length_button: 'Max length is 40',
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Alert banner</strong><br/>Publishing these changes will push them live to your app and/or website.',
      note: '<strong>Note:</strong> publishing changes will override banners configured with your ordering provider',
      publish: 'Publish',
    },
  },
  publish_button: {
    save_draft: 'Save draft',
  },
  form_error:
    'One or more fields are missing or incorrect. Please check the page for errors.',
  banners: {
    tab_title: 'Banners',
    submit_error: 'This Banner could not be saved. Please try again.',
    validation_error: 'Check the form for errors and try again.',
    submit_success: 'Your changes to your Banners have been saved.',
    modal: {
      publish_changes: 'Publish changes',
      you_are_publishing:
        'You are publishing changes to <strong>Banners</strong><br/>Publishing these changes will push them live to your app.',
      publish: 'Yes, publish changes',
    },
    sidebar: {
      publish: 'Publish',
      preview: 'Preview',
    },
    footer: {
      publish: 'Publish',
    },
    alert_banner: {
      subtab_title: 'Alert banner',
    },
    location_banner: {
      subtab_title: 'Order location banner',
      title: 'Order location banner (app only)',
      description:
        'This banner appears in the app’s navigation right below the location selector and draws the customer’s eye to the location name. Customers can dismiss this banner.',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      button_color: 'X button color',
    },
    order_placed_banner: {
      subtab_title: 'Placed order banner',
      title: 'Placed order banner (app only)',
      description:
        'This banner appears in the app’s navigation right below the menu categories and confirms that the customer recently placed an order. Customers can tap on this banner to see order details.',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      button_color: 'Chevron color',
    },
    error_banner: {
      subtab_title: 'Error banner',
      title: 'Error banner (app only)',
      description:
        'This banner appears on multiple pages in the ordering experience when the customer misconfigures their order, e.g., on the product detail page when the modifiers are misconfigured. Your customization below will only apply to the banners in the app.',
      background_color: 'Background color',
      header_text_color: 'Heading text color',
      body_text_color: 'Body text color',
      button_color: 'X button color',
    },
  },
}
