import InnerTabs from 'components/Tabs/InnerTabs'
import { buildTranslate } from 'locales'
import React from 'react'
import Analytics from './Analytics'
import OrderingConfiguration from './OrderingConfiguration'
import ReceiptSettings from './ReceiptSettings'

export enum Tabs {
  Locations = 'locations',
  OrderingConfiguration = 'ordering_configuration',
  ReceiptSettings = 'receipt_settings',
  Analytics = 'analytics',
}

const t = buildTranslate('cms')

const Settings: React.FC = () => {
  return (
    <InnerTabs
      tabs={[
        {
          key: Tabs.OrderingConfiguration,
          component: <OrderingConfiguration />,
          title: t('settings.ordering_configuration.title'),
        },
        {
          key: Tabs.ReceiptSettings,
          component: <ReceiptSettings />,
          title: t('settings.receipt_settings.title'),
        },
        {
          key: Tabs.Analytics,
          component: <Analytics />,
          title: t('settings.analytics.tab'),
        },
      ]}
    />
  )
}

export default Settings
