import React from 'react'
import Pendo from './Pendo'
import UserTabbing from './UserTabbing'

const Head: React.FC = () => {
  return (
    <>
      <UserTabbing />
      <Pendo />
    </>
  )
}

export default Head
