import React from 'react'
import { history } from 'store'
import OldDashboardIFrame from './OldDashboardIFrame'

const OldDashboardHandler: React.FC = () => {
  const path = history.location.pathname.slice(3)
  return <OldDashboardIFrame path={path} />
}

export default OldDashboardHandler
