import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.campaigns_menu')

type Props = {
  isLeftNav?: boolean
  onClick?: () => void
}

const CampaignsContent = (props: Props) => {
  const { isLeftNav = false, onClick = () => {} } = props
  const ability = useAbility()

  return (
    <>
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('overview')}
        link="/thanx_campaigns"
        disabled={ability.cannot('manage', 'MessageCampaign')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('targeted')}
        link="/thanx_campaigns/targeted"
        disabled={ability.cannot('manage', 'TargetedCampaign')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('automated')}
        link="/thanx_campaigns/automated"
        disabled={ability.cannot('manage', 'AutomatedCampaign')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        text={t('message')}
        link="/thanx_campaigns_m"
        disabled={ability.cannot('manage', 'MessageCampaign')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('reporting')}
        link="/thanx_campaigns/reports"
        disabled={ability.cannot('manage', 'CampaignReports')}
      />
    </>
  )
}

export default CampaignsContent
