import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Builder from './Builder'
import Creator from './Creator'
import Landing from './Landing'
import Automated from './Landing/Automated'
import Opportunity from './Landing/Opportunity'
import Reports from './Landing/Reports'
import Targeted from './Landing/Targeted'
import EditEmail from './Messaging/EditEmail'
import EditPush from './Messaging/EditPush'
import EditSms from './Messaging/EditSms'
import Report from './Report'
import ReportV3 from './Report/v3'

const CampaignCenter: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="Landing"
        exact
        path="/thanx_campaigns"
        component={Landing}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="CampaignReports"
        exact
        path="/thanx_campaigns/reports"
        component={Reports}
      />
      <Route
        exact
        path="/thanx_campaigns/message"
        render={() => <Redirect to="/thanx_campaigns" />}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="AutomatedCampaign"
        exact
        path="/thanx_campaigns/automated"
        component={Automated}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/targeted"
        component={Targeted}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="Opportunity"
        exact
        path="/thanx_campaigns/:category/:type/opportunity"
        component={Opportunity}
      />
      <Route
        exact
        path="/thanx_campaigns/:category/:type"
        component={Creator}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/:id/push/:variantId"
        component={EditPush}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/:id/sms/:variantId"
        component={EditSms}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/:id/email/:variantId"
        component={EditEmail}
      />
      <Route
        exact
        path="/thanx_campaigns/:category/:type/:id"
        component={Builder}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="CampaignReports"
        exact
        path="/thanx_campaigns/:category/:type/:id/report"
        component={Report}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns/targeted"
        resource="CampaignReports"
        exact
        path="/thanx_campaigns/:category/:type/:id/report/v3"
        component={ReportV3}
      />
      <Route
        exact
        path="/thanx_campaigns/:category/:type/:id/report/v2"
        render={() => <Redirect to="/thanx_campaigns" />}
      />
    </Switch>
  )
}

export default CampaignCenter
