import { Radio } from '@thanx/uikit/radio'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { merchantTheme } from '../../theme'

type Props = {
  checked: boolean
  value: string
  children: React.ReactNode
  border?: boolean
  onChange?: (value: string) => void
}

const RadioButton = ({
  checked,
  value,
  onChange,
  children,
  border = true,
}: Props) => {
  const [css] = useStyletron()
  return (
    <label
      className={`w-100 d-flex p-m align-items-start ${
        border
          ? css({
              transition:
                'border-color 300ms ease-out; background-color 300ms ease-out;',
              borderRadius: '4px',
              borderStyle: 'solid',
              borderWidth: '1px',
              ':hover': {
                borderColor: checked
                  ? merchantTheme.colors.grey90
                  : merchantTheme.colors.primary,
              },
            })
          : css({
              paddingTop: '10px !important',
              paddingBottom: '0 !important',
            })
      }`}
    >
      <div className="mt-n2">
        <Radio
          value={value}
          name={value}
          checked={checked}
          onChange={e => {
            onChange?.(e.target.value)
          }}
        />
      </div>
      <div className="w-100 pl-xs">{children}</div>
    </label>
  )
}

export default RadioButton
