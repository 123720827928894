import {
  GET_ANALYTICS_CONFIG_SUCCESS,
  GET_RECEIPT_CONFIG_SUCCESS,
  GET_TIERS_CONFIG_SUCCESS,
  ResolvedAction,
  UPDATE_ANALYTICS_CONFIG_SUCCESS,
} from 'actions/config'
import { RESET_DB } from 'actions/orm'
import { Fields as Tier } from 'models/Tier'
import { Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export interface ReceiptConfigApiFields {
  cash: boolean
  gift_card: boolean
  online: boolean
  third_party: boolean
  store_credit: boolean
}

export interface TiersConfigApiFields {
  bronze_tier: Tier | null
  silver_tier: Tier | null
  gold_tier: Tier | null
  progress_bar_image_urls?: ImageUrlsT
}

export interface AnalyticsConfigApiFields {
  google_measurement_id: string | null
  facebook_pixel_id: string | null
  google_tag_manager_id: string | null
}

export interface ReceiptConfig extends ReceiptConfigApiFields {
  id: 'receipt_config'
}
export interface TiersConfig extends TiersConfigApiFields {
  id: 'tiers_config'
}
export interface AnalyticsConfig extends AnalyticsConfigApiFields {
  id: 'ordering_analytics'
}
// This might hold other configuration types by union
export type Fields = ReceiptConfig | TiersConfig | AnalyticsConfig

// @ts-ignore
export default class Config extends Model<typeof Config, Fields> {
  static modelName: string = 'Config'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Config: ModelType<Config>
  ) {
    switch (action.type) {
      case UPDATE_ANALYTICS_CONFIG_SUCCESS:
        Config.upsert({
          id: 'ordering_analytics',
          ...action.payload.data.ordering_analytics,
        })
        break
      case GET_RECEIPT_CONFIG_SUCCESS:
        Config.upsert({
          id: 'receipt_config',
          ...action.payload.data.receipt_config,
        })
        break
      case GET_TIERS_CONFIG_SUCCESS: {
        if (action.payload.data.tiers_config) {
          Config.upsert({
            id: 'tiers_config',
            ...action.payload.data.tiers_config,
          })
        }
        break
      }
      case GET_ANALYTICS_CONFIG_SUCCESS: {
        Config.upsert({
          id: 'ordering_analytics',
          ...action.payload.data.ordering_analytics,
        })
        break
      }
      case RESET_DB:
        Config.all().delete()
        break
      default:
        break
    }
  }
}
