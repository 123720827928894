import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { MerchantUser, Role } from 'api/merchantUsers'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import compact from 'lodash/compact'
import React from 'react'
import { Link } from 'react-router-dom'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { useStyletron } from 'styletron-react'
import useCurrentMerchantUser from '../../hooks/useCurrentMerchantUser'

const t = buildTranslate('merchant_users.merchant_users_list.table')

type Props = {
  merchantUser: MerchantUser & { role?: string }
  onRemove: VoidFunction
}

const RoleNameMap: Record<Role, string> = {
  [Role.admin]: t('roles.admin'),
  [Role.accountant]: t('roles.accountant'),
  [Role.approver]: t('roles.approver'),
  [Role.customer_service_read]: t('roles.customer_service'),
  [Role.customer_service_write]: t('roles.customer_service'),
  [Role.mall]: t('roles.mall'),
  [Role.manager]: t('roles.manager'),
}

const Row = ({ merchantUser: mu, onRemove }: Props) => {
  const currentMerchantUser = useCurrentMerchantUser()
  const [css] = useStyletron()
  const name = compact([mu.first_name, mu.last_name]).join(' ')
  const status =
    mu.pending ?? true
      ? { title: t('invited'), disabled: true }
      : { title: t('active'), disabled: false }

  const hideActions = currentMerchantUser?.id !== mu.id

  return (
    <tr>
      <BodyCell
        isErrored={false}
        isLoading={false}
        values={[{ value: name }]}
      />
      <BodyCell
        isErrored={false}
        isLoading={false}
        values={[{ value: RoleNameMap[mu.role ?? ''] }]}
      />
      <BodyCellContainer
        className={'text-left'}
        isErrored={false}
        isLoading={false}
      >
        <Badge
          value={status.title}
          className={css({
            backgroundColor: status.disabled ? '#E9EAEC' : '#D6F5E4',
          })}
        />
      </BodyCellContainer>
      <BodyCellContainer
        className={'text-right'}
        isErrored={false}
        isLoading={false}
      >
        <Switch condition={hideActions}>
          <Link to={`/merchant_users/edit/${mu.id}`}>
            <Text.BodyText4 tag="span" className="ml-xs mb-0" color="primary">
              {t('edit')}
            </Text.BodyText4>
          </Link>
        </Switch>
      </BodyCellContainer>
      <BodyCellContainer
        className={'text-right'}
        isErrored={false}
        isLoading={false}
      >
        <Switch condition={hideActions}>
          <span onClick={onRemove}>
            <Text.BodyText4
              tag="span"
              className={`ml-xs mb-0 ${css({ cursor: 'pointer' })}`}
              color="primary"
            >
              {t('remove')}
            </Text.BodyText4>
          </span>
        </Switch>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
