import { Text } from '@thanx/uikit/text'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import type { Fields as Location } from 'models/Location'
import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import LocationFilter from './LocationFilter'
import RecentlyRewarded from './RecentlyRewarded'

type Props = {
  campaign: Campaign
  isLoading: boolean
  customerCount: number
  isDisabled: boolean
  isExclusionChecked: boolean
  onLinkClicked: () => void
  onEdit: () => void
  locationIds?: number[]
  categoryIds?: number[]
  promotedLocation?: Location | null
  displayLocationDetails: boolean
}

const Audience: React.FC<Props> = props => {
  const {
    campaign,
    isLoading,
    customerCount,
    isDisabled,
    isExclusionChecked,
    onLinkClicked,
    onEdit,
    locationIds,
    categoryIds,
    promotedLocation,
    displayLocationDetails,
  } = props

  const { config_target_name: targetName } = campaign

  function customersLinkClicked() {
    if (isDisabled) return
    onLinkClicked()
  }

  const hasLocationCountDescription =
    !displayLocationDetails && promotedLocation && promotedLocation.street

  const showLocationFilters =
    (locationIds && locationIds.length > 0) ||
    (categoryIds && categoryIds.length > 0)

  const t = buildTranslate('thanx_campaigns.review.customers')

  return (
    <div>
      <Text.Header4 tag="div" className="mb-xs mt-xxs">
        {t('title')}
      </Text.Header4>
      <div className="margin-bottom-medium font-size-16 grey-70">
        {t('text.future')}
        &nbsp;
        <span className="a" onClick={customersLinkClicked}>
          <strong>
            <Spinner isLoading={isLoading} className="inline-block">
              {numeral(customerCount).format('0,0')}
            </Spinner>
            &nbsp;
            {pluralize(t('unit'), customerCount)}
          </strong>
        </span>
        {targetName && (
          <span>
            {` ${t('in_segment')} `}
            <b>{targetName}</b>
          </span>
        )}
        {hasLocationCountDescription && (
          <span>
            &nbsp;
            {t('text.location_count').toLowerCase()}
            &nbsp;
            <strong>{promotedLocation?.street}</strong>
          </span>
        )}
      </div>

      {displayLocationDetails && (
        <div className="margin-bottom-medium font-size-16 grey-70">
          {t('text.location_address.negative')}
          &nbsp;
          <strong>{promotedLocation?.street}</strong>
        </div>
      )}

      {showLocationFilters && (
        <LocationFilter
          className="margin-bottom-medium"
          isLoading={isLoading}
          onEdit={onEdit}
          locationIds={locationIds || []}
          categoryIds={categoryIds || []}
        />
      )}
      <RecentlyRewarded
        isChecked={isExclusionChecked}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default Audience
