import { Text } from '@thanx/uikit/text'
import { Role } from 'api/merchantUsers'
import { buildTranslate } from 'locales'
import React from 'react'
import CustomerServiceCardDetails from './CustomerServiceCardDetails'
import ManagerCardDetails from './ManagerCardDetails'
import RadioButton from './RadioButton'

const t = buildTranslate('merchant_users.form')

type Props = {
  role: Role
  hideHeader?: boolean
  onChange?: (role: Role) => void
}

const RolesCard = ({ role = Role.admin, onChange }: Props) => {
  const Details = ({ role }) => (
    <>
      <Text.Header4 bold className="mt-xxs">
        {t(`roles.${role}.title`)}
      </Text.Header4>
      <Text.BodyText4 color="grey70" tag="p">
        {t(`roles.${role}.description`)}
      </Text.BodyText4>
    </>
  )

  return (
    <>
      <RadioButton
        key={Role.admin}
        checked={role === Role.admin}
        value={Role.admin}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Details role={Role.admin} />
      </RadioButton>

      <RadioButton
        key={Role.manager}
        checked={role === Role.manager}
        value={Role.manager}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Details role={Role.manager} />
        <ManagerCardDetails visible={role === Role.manager} />
      </RadioButton>

      <RadioButton
        key={'customer_service'}
        checked={
          role === Role.customer_service_read ||
          role === Role.customer_service_write
        }
        value={'customer_service'}
        onChange={() => {
          if (
            role !== Role.customer_service_write &&
            role !== Role.customer_service_read
          ) {
            onChange?.(Role.customer_service_write)
          }
        }}
      >
        <Details role={'customer_service'} />
        <CustomerServiceCardDetails
          role={role}
          visible={
            role === Role.customer_service_write ||
            role === Role.customer_service_read
          }
          onChange={value => {
            onChange?.(value as Role)
          }}
        />
      </RadioButton>

      <RadioButton
        key={Role.accountant}
        checked={role === Role.accountant}
        value={Role.accountant}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Details role={Role.accountant} />
      </RadioButton>
    </>
  )
}

export default RolesCard
