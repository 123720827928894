import React from 'react'

type Props = {
  className?: string
  value: React.ReactNode
}

const Item: React.FC<Props> = ({ className, value }) => {
  if (!value) return null
  return (
    <div className={`py-xxs px-xs rounded small-caps-3 d-inline ${className}`}>
      {value}
    </div>
  )
}

export default Item
