import { AbilityBuilder } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'

import type { Fields as MerchantUser, Role } from 'models/MerchantUser'

export const AbilityContext = createContext<any>(null)

export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const merchantId = useSelector((state: any) => state.auth.current_merchant_id)
  const merchantUser = useSelector(selectCurrentMerchantUser)

  const ability = defineAbilitiesFor(merchantUser, merchantId)

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

export function useAbility() {
  const ability = useContext(AbilityContext)

  if (ability === undefined) {
    throw new Error('Needs to be in an AbilityProvider')
  }
  return ability
}

// permissions default to off, so must be explicitly granted
export function defineAbilitiesFor(
  merchantUser: MerchantUser | null,
  merchantId: number
) {
  if (!merchantUser) {
    return AbilityBuilder.define(can => {
      can('manage', 'Login')
    })
  }
  //@ts-ignore
  return defineAbilitiesForRoles(merchantUser.rolesForMerchantId(merchantId))
}

export function defineAbilitiesForRoles(roles: Role[]) {
  return AbilityBuilder.define((can, cannot) => {
    if (roles.length === 0) {
      can('manage', 'Login')
      return
    }

    // Apply all `can` rules first

    if (roles.includes('manager')) {
      cannot('manage', 'all')
      can('targetCategorizedLocations', 'Campaign')
      can('manage', 'TargetedCampaign')
      can('manage', 'CampaignReports')
      cannot('manage', 'OperationalReports')
      can('manage', 'Results')
      can('manage', 'Insights')
      can('manage', 'Feedback')
      can('access', 'TargetedCampaign::Custom')
      cannot('manage', 'Points')
      can('manage', 'Users')
      cannot('manage', 'LegacyReports')
      can('manage', 'OrderingManagement')
      can('access', 'LocationReports')
      can('access', 'LocationDetailReports')
      can('access', 'NpsReports')
      can('access', 'UserSupport')
    }

    if (roles.includes('approver')) {
      can('targetCategorizedLocations', 'Campaign')
      can('manage', 'TargetedCampaign')
      can('manage', 'CampaignReports')
      can('access', 'TargetedCampaign::Custom')
      can('review', 'Campaign')
      can('access', 'Points')
      cannot('manage', 'OperationalReports')
      cannot('manage', 'RedeemManager')
      cannot('access', 'CMS')
      cannot('access', 'MerchantUsers')
      cannot('manage', 'OrderingManagement')
      cannot('manage', 'Feedback')
      cannot('access', 'Feedback')
    }

    // Removes previous role access if these roles are present
    if (roles.includes('accountant')) {
      cannot('manage', 'all')
      can('access', 'Billing')
      can('access', 'Points')
      can('access', 'RedeemManager')
    }

    // Removes previous role access if these roles are present
    if (roles.includes('customer_service_read')) {
      cannot('manage', 'all')
      can('access', 'Feedback')
      can('access', 'NpsReports')
    }

    // Removes previous role access if these roles are present
    if (roles.includes('customer_service_write')) {
      cannot('manage', 'all')
      can('manage', 'Feedback')
      can('access', 'NpsReports')
    }

    if (roles.includes('admin')) {
      can('manage', 'all')
      can('manage', 'Feedback')
      can('access', 'Broadcasts')
      can('access', 'AutomatedCampaign::ReputationManager')
      can('access', 'TargetedCampaign::Location')
      can('targetCategorizedLocations', 'Campaign')
      can('review', 'Campaign')
      can('schedule', 'Campaign')
      can('targetAllLocations', 'Campaign')
      can('manage', 'RedeemManager')
      can('manage', 'Points')
      can('manage', 'CMS')
      can('manage', 'Billing')
      can('manage', 'MerchantUsers')
      can('manage', 'Broadcasts')
      can('manage', 'Users')
      can('manage', 'LegacyReports')
      can('manage', 'OperationalReports')
      can('manage', 'LocationReports')
      can('manage', 'LocationDetailReports')
      can('manage', 'NpsReports')
      can('manage', 'OrderingManagement')
      can('access', 'UserSupport')
      cannot('manage', 'Login')
    }

    // Apply all `cannot` rules
    if (roles.includes('mall')) {
      cannot('targetCategorizedLocations', 'Campaign')
      cannot('access', 'TargetedCampaign::Location')
      cannot('access', 'AutomatedCampaign::ReputationManager')
      cannot('targetAllLocations', 'Campaign')
      cannot('access', 'Feedback')
      cannot('access', 'OperationalReports')
      cannot('access', 'LocationDetailReports')
    }
  })
}
