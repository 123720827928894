import { Switch } from '@thanx/uikit/switch'
import { scheduleCampaign } from 'actions/campaigns'

import { merchantUsersApi } from 'api/merchantUsers'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import React, { useState } from 'react'
import Alert from 'react-bootstrap-five/Alert'
import Button from 'react-bootstrap-five/Button'
import Col from 'react-bootstrap-five/Col'
import Row from 'react-bootstrap-five/Row'
import { Translate } from 'react-redux-i18n'
import Select from './Select'

type Props = {
  onCancelClick: () => void
  closeModal: () => void
  campaignId: number
  onApprove: () => void
}

const Approvers = (props: Props) => {
  const { onCancelClick, campaignId, closeModal, onApprove } = props
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(true)
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false)
  const dispatch = useDispatch()

  const {
    data,
    isLoading: isQueryLoading,
    isError: isQueryError,
  } = merchantUsersApi.useGetMerchantUsersQuery({
    roles: ['approver', 'admin'],
  })
  const merchantUsers = data?.merchant_users || []

  const isLoading = isQueryLoading || isSubmitLoading
  const isError = isQueryError || isSubmitError

  const submit = async (model: { approver_id: number }) => {
    setIsSubmitLoading(true)

    const action = await dispatch(scheduleCampaign(campaignId, model))
    setIsSubmitLoading(false)

    if (action.error) {
      setIsSubmitError(true)
      return
    }

    onApprove()
    closeModal()
  }

  const errorI18n = `thanx_campaigns.builder.confirm_modal.request_review.${
    isError ? 'error' : 'no_approvers'
  }`

  return (
    <>
      <Switch condition={!isLoading && (isError || merchantUsers.length < 1)}>
        <Alert variant="danger">
          <Translate value={errorI18n} />
        </Alert>
      </Switch>
      <Formsy
        className="text-left mt-xxl form-group form-group-lg"
        onValidSubmit={submit}
      >
        <Switch condition={merchantUsers.length > 0}>
          <Select merchantUsers={merchantUsers} />
        </Switch>
        <Row className="mt-m">
          <Col sm={6}>
            <Button
              variant="info"
              size="lg"
              className="full-width"
              onClick={onCancelClick}
            >
              <Translate
                value={
                  'thanx_campaigns.builder.confirm_modal.request_review.buttons.exit'
                }
              />
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              onClick={() => {}}
              variant="primary"
              type="submit"
              size="lg"
              className="full-width"
              disabled={isLoading || isError || merchantUsers.length < 1}
            >
              <Translate
                value={
                  'thanx_campaigns.builder.confirm_modal.request_review.buttons.finish'
                }
              />
            </Button>
          </Col>
        </Row>
      </Formsy>
    </>
  )
}

export default Approvers
