import Badge from 'components/Badge'
import React from 'react'

type Props = {
  values?: string[]
  className?: string
}

const Badges: React.FC<Props> = ({ values, className = '' }) => {
  if (!values?.length) return null

  return (
    <div className={`flex-row ${className}`}>
      {values.map(item => (
        <Badge
          className="grey-70 mr-xs grey-20-bkg d-inline"
          key={item}
          value={item}
        />
      ))}
    </div>
  )
}

export default Badges
