import { alert } from 'actions/flash'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useMemo, useState } from 'react'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import { push } from 'react-router-redux'
import { rootUrl } from 'utilities/urlUtils'

type PropsT = { path: string }

const t = buildTranslate('titles')

const OldDashboardIFrame: React.FC<PropsT> = ({ path }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isShowingOldDashboard, setIsShowingOldDashboard] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(true)
  }, [path])

  const simplifiedPath = path.includes('member_profile')
    ? 'member_profile'
    : path
  const title = useMemo(() => t(simplifiedPath), [simplifiedPath])

  const host = process.env.REACT_APP_MERCHANT_ROOT_URL || ''

  const iFrameMessageHandler = event => {
    if (event.data.source !== 'oldDashboard') return
    if (!isShowingOldDashboard) {
      setIsShowingOldDashboard(true)

      const remoteLocation = event.data.payload.location.slice(1)
      const localLocation = window.location.pathname.slice(3)

      if (remoteLocation !== localLocation) {
        dispatch(push('/o/' + remoteLocation))
      }
    }
  }

  window.addEventListener('message', iFrameMessageHandler, false)

  const onLoadHandler = () => {
    setIsLoading(false)
    if (!isShowingOldDashboard) {
      // order matters here; changing urls (with 'push') clears the store.
      dispatch(push(rootUrl))
      dispatch(
        alert({
          key: 'danger',
          message: I18n.t('errors.inaccessible'),
        })
      )
    }
  }

  if (path.includes('dashboard')) {
    dispatch(push(rootUrl))
    return null
  }
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <iframe
        src={`${host}/${path}?use_iframe=true`}
        title={path}
        className="w-100 border-0 h-100"
        onLoad={onLoadHandler}
      />
      <Spinner
        isLoading={isLoading}
        size="4x"
        className="text-center w-100 absolute t-0"
      />
    </div>
  )
}

export default OldDashboardIFrame
