import {
  GetCampaignsAction,
  GET_CAMPAIGNS_SUCCESS,
  ResolvedAction,
} from 'actions/campaigns'
import {
  GetCardsAction,
  GET_CARDS_SUCCESS,
  ResolvedAction as CardsResolvedAction,
} from 'actions/cards'
import {
  GetItemPurchasesAction,
  GET_ITEM_PURCHASES_SUCCESS,
  ResolvedAction as PurchasesResolvedAction,
} from 'actions/itemPurchases'
import { RESET_DB } from 'actions/orm'
import {
  UpdateCampaignsCurrentPageAction,
  UPDATE_PAGINATION_CURRENT_PAGE,
} from 'actions/pagination'
import {
  GetUserCampaignsAction,
  GetUserItemsAction,
  GET_USER_CAMPAIGNS_SUCCESS,
  GET_USER_ITEMS_SUCCESS,
} from 'actions/users'
import {
  GetUserEventsAction,
  GetUserFeedbacksAction,
  GetUserRewardsAction,
  GetUsersAction,
  GET_USERS_SUCCESS,
  GET_USER_EVENTS_SUCCESS,
  GET_USER_FEEDBACKS_SUCCESS,
  GET_USER_REWARDS_SUCCESS,
  ResolvedAction as UsersResolvedAction,
} from 'actions/users'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'
import { CampaignStateT } from './Campaign'

type CampaignCategory = 'automated' | 'targeted'

export type CampaignPaginationT = {
  type: 'campaign'
  id: `${CampaignStateT | 'all'}-${CampaignCategory | 'all'}`
}

type UserPaginationT = {
  type: 'user'
  id: 'all'
}

type UserEventsPaginationT = {
  type: 'user_event'
  id: string
}

type UserRewardsPaginationT = {
  type: 'user_reward'
  id: string
}

type UserFeedbacksPaginationT = {
  type: 'user_feedback'
  id: string
}

type UserCampaignsPaginationT = {
  type: 'user_campaigns'
  id: string
}

type UserItemsPaginationT = {
  type: 'user_items'
  id: string
}

type ItemPurchasesPaginationT = {
  type: 'item_purchases'
  id: number
}

type UserCardsPaginationT = {
  type: 'user_cards'
  id: string
}

export type PaginationType =
  | CampaignPaginationT
  | UserPaginationT
  | UserEventsPaginationT
  | UserRewardsPaginationT
  | UserFeedbacksPaginationT
  | UserCampaignsPaginationT
  | UserItemsPaginationT
  | ItemPurchasesPaginationT
  | UserCardsPaginationT

type PaginationIdT = `${PaginationType['type']}-${PaginationType['id']}`

export type PaginationT = {
  total_pages: number
  per_page: number
  current_page: number
  total_count: number
}

export type Fields = {
  id: PaginationIdT
  record_ids: Record<number, Array<number>>
} & PaginationT

export default class Pagination extends Model<
  // @ts-ignore
  typeof Pagination,
  Fields
> {
  static modelName: string = 'Pagination'

  static get fields(): any {
    return {
      id: attr(),
      record_ids: attr(),
      total_pages: attr(),
      per_page: attr(),
      current_page: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | UsersResolvedAction
      | { type: typeof RESET_DB }
      | GetCampaignsAction
      | GetUsersAction
      | UpdateCampaignsCurrentPageAction
      | GetUserCampaignsAction
      | PurchasesResolvedAction
      | CardsResolvedAction,
    // @ts-ignore
    Pagination: ModelType<Pagination>
  ) {
    switch (action.type) {
      case GET_CAMPAIGNS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetCampaignsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        if (params.page) {
          const state: CampaignStateT = params.state ?? 'all'
          const category = params.category ?? 'all'
          const id: PaginationIdT = `campaign-${state}-${category}`
          const campaignIds = Pagination.withId(id)?.record_ids ?? {}
          Pagination.upsert({
            id,
            record_ids: {
              ...campaignIds,
              [params.page]: action.payload.data.campaigns.map(c => c.id),
            },
            ...action.payload.data.pagination,
          })
        }
        break
      }
      case GET_USERS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetUsersAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const id: PaginationIdT = 'user-all'
        const userIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...userIds,
            [params.page]: action.payload.data.users.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_USER_EVENTS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetUserEventsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const userId = url.split('/')[1]
        const id: PaginationIdT = `user_event-${userId}`
        const eventIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...eventIds,
            [params.page]: action.payload.data.events.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_USER_REWARDS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetUserRewardsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const userId = url.split('/')[1]
        const id: PaginationIdT = `user_reward-${userId}`
        const rewardIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...rewardIds,
            [params.page]: action.payload.data.rewards.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_USER_FEEDBACKS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetUserFeedbacksAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const userId = url.split('/')[1]
        const id: PaginationIdT = `user_feedback-${userId}`
        const feedbackIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...feedbackIds,
            [params.page]: action.payload.data.feedbacks.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_USER_CAMPAIGNS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetUserCampaignsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const userId = url.split('/')[1]
        const id: PaginationIdT = `user_campaigns-${userId}`
        const campaignIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...campaignIds,
            [params.page]: action.payload.data.campaign_messages.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_USER_ITEMS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetUserItemsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const userId = url.split('/')[1]
        const id: PaginationIdT = `user_items-${userId}`
        const itemIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...itemIds,
            [params.page]: action.payload.data.purchase_items.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_ITEM_PURCHASES_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetItemPurchasesAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const tagParam = url.split('?')[1]
        const tagId = tagParam.split('=')[1]
        const id: PaginationIdT = `item_purchases-${tagId}`
        const itemIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...itemIds,
            [params.page]: action.payload.data.purchases.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case GET_CARDS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetCardsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}

        const url = previousAction.payload.request.url ?? ''
        const userId = url.split('/')[1]
        const id: PaginationIdT = `user_cards-${userId}`
        const itemIds = Pagination.withId(id)?.record_ids ?? {}

        Pagination.upsert({
          id,
          record_ids: {
            ...itemIds,
            [params.page]: action.payload.data.cards.map(c => c.id),
          },
          ...action.payload.data.pagination,
        })
        break
      }
      case UPDATE_PAGINATION_CURRENT_PAGE:
        Pagination.upsert({
          id: `${action.kind}-${action.id}`,
          current_page: action.page,
        })
        break
      case RESET_DB:
        Pagination.all().delete()
        break
      default:
        break
    }
  }
}
