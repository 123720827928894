import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  ActivityTimeSeriesMetric,
  ShortType,
} from 'models/PointsExperienceMetric'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getSummaryActivity,
  SortFilter,
  SortFilterAttribute,
  sortSummaryActivityData,
} from 'scenes/Points/Landing/Activity/utilities'
import { selectPointsExperienceMetricByType } from 'selectors/pointsExperienceMetric'
import HeaderCell from './HeaderCell'
import Row from './Row'

const t = buildTranslate('points.activity.summary_table')

const SummaryTable: React.FC = () => {
  const showExpiredPoints = useFlag('show-points-expired-report', false)
  const { filter } = useContext(DateRangeFilterContext)
  const [sortFilter, setSortFilter] = useState<SortFilter>({
    attribute: 'dateTime',
    direction: 'desc',
  })

  const timeSeriesAllTimeState = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      ShortType.ACTIVITY_TIME_SERIES_ALL_TIME
    )
  ) as ActivityTimeSeriesMetric

  if (!timeSeriesAllTimeState) {
    return null
  }

  const summaryActivity = getSummaryActivity({ timeSeriesAllTimeState, filter })
  const activityData = sortFilter
    ? sortSummaryActivityData(summaryActivity.activityData, sortFilter)
    : summaryActivity.activityData

  const { isErrored, isLoading, time_zone, granularity } =
    timeSeriesAllTimeState

  const displayGranularity = ((): string => {
    switch (granularity) {
      case 'daily':
        return t('headers.day')
      case 'weekly':
        return t('headers.week')
      case 'monthly':
        return t('headers.month')
      case 'quarterly':
        return t('headers.quarter')
      case 'yearly':
        return t('headers.year')
    }
  })()

  const headers: Array<SortFilterAttribute> = showExpiredPoints
    ? [
        'outstandingAtStart',
        'issued',
        'redeemed',
        'expired',
        'outstandingAtEnd',
      ]
    : ['outstandingAtStart', 'issued', 'redeemed', 'outstandingAtEnd']

  return (
    <section className="mt-xxl">
      <h3 className="mt-0 mb-l inline-block">{t('title')}</h3>
      <table id="points-summary-table" className="w-100">
        <thead>
          <tr key="header">
            <HeaderCell
              onSort={setSortFilter}
              attribute={'dateTime'}
              label={displayGranularity}
              style={{ width: '30%' }}
              className={'justify-content-between'}
              sortFilter={sortFilter}
            />
            {headers.map(attribute => (
              <HeaderCell
                onSort={setSortFilter}
                attribute={attribute}
                label={t(`headers.${attribute}`)}
                className={'justify-content-end'}
                sortFilter={sortFilter}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {activityData.map(row => (
            <Row
              activitySummaryData={row}
              dateTime={row.dateTime}
              granularity={granularity}
              timeZone={time_zone}
              isErrored={!!isErrored}
              isLoading={!!isLoading}
              showExpiredPoints={showExpiredPoints}
            />
          ))}
        </tbody>
      </table>
    </section>
  )
}

export default SummaryTable
