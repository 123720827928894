import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Spinner from 'components/Spinner'
import TooltipLabel from 'components/TooltipLabel'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import {
  CouponPoolGenerationType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useFormModel } from 'scenes/RedeemManager/Builder/FormModel'
import useCouponPools from 'scenes/RedeemManager/Builder/useCouponPools'
import { RedeemTypeItem } from 'scenes/RedeemManager/types'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import ProductInput from '../../ProductInput'

type PropsT = {
  typeItem: RedeemTypeItem | null
  template: RedeemTemplate | null
  couponPool?: CouponPool
  isDirect?: boolean
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes'
)

const ThanxGeneratedEnabled: React.FC<PropsT> = ({
  typeItem,
  template,
  isDirect = false,
}) => {
  const couponPools = useSelector(state => {
    if (template?.id) {
      return selectCouponPoolsByTemplateId(state, template.id)
    }
  })
  const [isLoading, setIsLoading] = useState(true)
  const couponPool = !!couponPools?.length ? couponPools[0] : null
  const isFreeItem = typeItem?.types.includes('manual/item')
  const discountedProductIds = template?.product_ids?.instore?.discounted || []
  const requiredProductIds = template?.product_ids?.instore?.required || []
  const displayRequiredProducts =
    template?.subtype === 'bogo/item' ||
    template?.subtype === 'bogo/purchase' ||
    (template?.subtype === null && !isEmpty(requiredProductIds))
  const displayDiscountedProducts =
    template?.subtype === 'item' ||
    template?.subtype === 'bogo/item' ||
    (template?.subtype === null && !isEmpty(discountedProductIds))

  const formModel = useFormModel().model
  const { createCouponPool } = useCouponPools()

  useEffect(() => {
    async function fetchCouponPools() {
      if (!template?.id) return
      setIsLoading(true)
      await createCouponPool({
        templateId: template.id,
        generationType: CouponPoolGenerationType.DYNAMIC,
        returnExistingPool: true,
      })
      setIsLoading(false)
    }
    fetchCouponPools()
  }, [createCouponPool, template?.id])

  useEffect(() => {
    if (!!couponPools?.length) {
      formModel.selectedCouponPools = [couponPool]
    }
  }, [couponPools, couponPool, formModel])

  const tooltipSections = [
    {
      title: t('type_tooltip.pool_title'),
      content: t('type_tooltip.pool_content'),
    },
    {
      title: t('type_tooltip.reusable_title'),
      content: t('type_tooltip.reusable_content'),
    },
    {
      title: t('type_tooltip.unique_title'),
      content: t('type_tooltip.unique_content_1'),
    },
    {
      title: '',
      content: t('type_tooltip.unique_content_2'),
    },
  ]

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className="w-100 pl-l mt-s mb-m">
        <TooltipLabel
          className="mb-xs font-size-14 bold"
          label={t('type_label')}
          sections={tooltipSections}
        />
        <Text.BodyText3 className="mb-xs" color="grey70" tag="div">
          {t('type.dynamic')}
        </Text.BodyText3>
        <Text.BodyText4 className="mb-m" color="grey70" tag="div">
          <span
            dangerouslySetInnerHTML={{
              __html: isDirect
                ? t('dynamic.entered_by_customer')
                : t('dynamic.issued_5_digit_code'),
            }}
          />
        </Text.BodyText4>

        {/*
          Product ids should be shown if the coupon generation type
          is dynamic and also if it's not a reward applied to entire
          purchase.
          Dynamic types should only have one pool, so we pick the
          first one
        */}
        <Switch
          condition={
            couponPool?.generation_type === CouponPoolGenerationType.DYNAMIC &&
            template?.subtype !== 'purchase'
          }
        >
          <Switch condition={!!couponPool && displayRequiredProducts}>
            <ProductInput
              label={t('required_label_fallback')}
              name="instoreRequiredProductIds"
              className="mb-m"
              value={requiredProductIds}
              validations={{ isRequired: true }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
              }}
            />
          </Switch>
          <ProductInput
            label={t(
              isFreeItem ? 'discounted_label_free_item' : 'discounted_label'
            )}
            name="instoreDiscountedProductIds"
            value={discountedProductIds}
            validations={{ isRequired: true }}
            validationErrors={{
              isRequired: I18n.t('validations.is_required'),
            }}
          />
        </Switch>
        <Switch
          condition={displayRequiredProducts && !displayDiscountedProducts}
        >
          <p className="body-text-14 bold">{t('then_entire_purchase')}</p>
        </Switch>
      </div>
    </>
  )
}

export default ThanxGeneratedEnabled
