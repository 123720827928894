import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import RestrictionSection from './RestrictionSection'

type Props = {
  value: number
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const RedeemMaximumInput: React.FC<Props> = ({ value }) => {
  return (
    <RestrictionSection
      title={t('maximum.title')}
      subtitle={t('maximum.subtitle')}
      hasToggle={false}
    >
      <Input
        name="redeem_maximum"
        value={value}
        label={t('maximum.title')}
        placeholder={t('maximum.placeholder')}
        addOnBefore="$"
        validations={{
          isRequired: true,
          isNumeric: true,
          greaterThanOrEqual: 0,
        }}
        validationErrors={{
          isRequired: I18n.t('validations.is_required'),
          isNumeric: I18n.t('validations.is_numeric'),
          greaterThanOrEqual: I18n.t('validations.greater_than', {
            value: 0,
          }),
        }}
      />
    </RestrictionSection>
  )
}

export default RedeemMaximumInput
