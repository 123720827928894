import { Button, Kind } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { FormsyTextArea } from '@thanx/uikit/textarea'
import { alert } from 'actions/flash'
import {
  requestCustomCampaign,
  REQUEST_CUSTOM_CAMPAIGN_FAIL,
} from 'actions/merchant'
import { getTargetSegment } from 'actions/targetSegments'
import Modal from 'components/Modal'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { selectTargetSegmentsByMerchant } from 'selectors/targetSegment'
import { Can } from 'utilities/ability'
import HowItWorks from './HowItWorks'

type Props = {
  title: string
  subtitle?: string
  defaultText?: string
  defaultSegmentId?: number | null
  show: boolean
  onModalClose: () => void
  onCreate: (targetSegment?: TargetSegment) => Promise<void>
  excludedSegmentCategories?: Array<string>
}

const t = buildTranslate(
  'thanx_campaigns.landing.targeted.custom_campaign_modal'
)

const CustomCampaignsModal: React.FC<Props> = props => {
  const {
    title,
    subtitle,
    defaultText,
    show,
    onModalClose,
    onCreate,
    excludedSegmentCategories,
  } = props

  const targetSegments = useSelector(selectTargetSegmentsByMerchant).filter(
    s =>
      !excludedSegmentCategories ||
      !excludedSegmentCategories.includes(s.category)
  )

  const initialSelectedSegment =
    defaultText || !props.defaultSegmentId || isEmpty(targetSegments)
      ? null
      : targetSegments.find(segment => segment.id === props.defaultSegmentId)

  const [selectedSegment, setSelectedSegment] = useState<
    TargetSegment | null | undefined
  >(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [userCount, setUserCount] = useState<number | null>(null)

  function resetForm() {
    setSelectedSegment(initialSelectedSegment)
  }

  const dispatch = useDispatch()
  useEffect(resetForm, [initialSelectedSegment])
  useEffect(() => {
    const fetchUserCount = async () => {
      if (!selectedSegment) return

      setUserCount(null)
      const result = await dispatch(getTargetSegment(selectedSegment.id))
      if (result.error) {
        dispatch(
          alert({
            key: 'danger',
            message: t('form.existing.reach.error'),
          })
        )
      } else {
        setUserCount(result.payload.data.target_segment.count)
      }
    }
    fetchUserCount()
  }, [selectedSegment, dispatch])

  function onClose() {
    resetForm()
    onModalClose()
  }

  async function onSubmitNew({
    message,
    name,
  }: {
    message: string
    name: string
  }) {
    setIsSubmitting(true)

    const response = await dispatch(requestCustomCampaign({ message, name }))

    if (response.type === REQUEST_CUSTOM_CAMPAIGN_FAIL) {
      const error = response.error?.response?.data?.error || null
      dispatch(
        alert({
          key: 'danger',
          message: t('form.new.errors.submit'),
          error,
        })
      )
      return
    }
    await onCreate()
    setIsSubmitting(false)
    dispatch(
      alert({
        key: 'success',
        message: t('form.new.success'),
        timeout: 3,
      })
    )
    onModalClose()
  }

  async function onSubmitExisting(model: { targetSegmentId: number }) {
    if (!onCreate) return

    const targetSegment = targetSegments.find(
      segment => segment.id === model.targetSegmentId
    )

    setIsSubmitting(true)
    await onCreate(targetSegment)
    setIsSubmitting(false)
  }

  const segmentOptions = targetSegments.map(segment => ({
    label: segment.name,
    value: segment.id,
  }))
  const options = [
    {
      label: <div className="belize-hole-50">{t('form.create_new')}</div>,
      value: 'create_new',
    },
    ...segmentOptions,
  ]

  function onChange(value: number | 'create_new') {
    if (value === 'create_new') {
      setSelectedSegment(null)
      return
    }

    setSelectedSegment(targetSegments.find(segment => segment.id === value))
  }

  const select = (
    <FormsySelect
      // @ts-ignore
      name="targetSegmentId"
      label="Audience"
      options={options}
      onChange={onChange}
      className="mb-l"
      value={selectedSegment ? selectedSegment.id : 'create_new'}
      overrides={{
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  // Make sure the dropdown displays on top of the modal
                  zIndex: 20000,
                },
              },
            },
          },
        },
      }}
    />
  )

  const createNewForm = (
    <Formsy onValidSubmit={onSubmitNew}>
      <FormsyInput
        // @ts-ignore
        className="mb-l uikit-input"
        name="name"
        label={t('form.new.name_label')}
        description={t('form.new.name_description')}
        validations={{ isRequired: true }}
        validationErrors={{
          isRequired: t('form.new.errors.name_required'),
        }}
      />
      <FormsyTextArea
        // @ts-ignore
        className="mb-xl"
        name="message"
        placeholder={t('form.new.message_placeholder')}
        value={defaultText}
        label={t('form.new.message_label')}
        description={t('form.new.message_description')}
        validations={{ isRequired: true }}
        validationErrors={{
          isRequired: t('form.new.errors.message_required'),
        }}
        rows={5}
      />
      <div className="d-flex justify-content-end">
        <Button
          kind={Kind.SECONDARY}
          className="mr-s"
          type="button"
          onClick={onClose}
        >
          {t('form.new.cancel_button')}
        </Button>
        <Button kind={Kind.PRIMARY} type="submit" isLoading={isSubmitting}>
          {t('form.new.submit_button')}
        </Button>
      </div>
    </Formsy>
  )

  const createExistingForm = (
    <>
      <div className="small-caps-3 mb-xs grey-90">
        {t('form.existing.reach.label')}
      </div>
      <div className="grey-70 mb-s">
        {userCount === null ? (
          <span className="fa fa-spin fa-circle-o-notch" />
        ) : (
          t('form.existing.reach.details', {
            count: userCount.toLocaleString(),
          })
        )}
      </div>
      <div className="small-caps-3 mb-xs grey-90">
        {t('form.existing.description_label')}
      </div>
      <div className="grey-70">{selectedSegment?.description}</div>
      <Can not I="targetAllLocations" on="Campaign" this={undefined}>
        <div className="small-caps-3 mt-l mb-xs grey-90">
          {t('form.existing.locations_limit.label')}
        </div>
        <div className="grey-70">
          {t('form.existing.locations_limit.description')}
        </div>
      </Can>

      <div className="text-right mt-xxl">
        <Button
          kind={Kind.SECONDARY}
          className="mr-s"
          type="button"
          onClick={onClose}
        >
          {t('form.existing.cancel_button')}
        </Button>
        <Button kind={Kind.PRIMARY} type="submit" isLoading={isSubmitting}>
          {t('form.existing.submit_button')}
        </Button>
      </div>
    </>
  )

  return (
    <Modal show={show} onModalClose={onClose} size="lg">
      <Container fluid>
        <Row>
          <Col className="padding-huge">
            <h3 className="mb-xs grey-90-text">{title}</h3>
            <div className="body-text-3 grey-90-text mb-l">{subtitle}</div>
            <Formsy onValidSubmit={onSubmitExisting}>
              {select}
              {selectedSegment ? createExistingForm : null}
            </Formsy>
            {!selectedSegment ? createNewForm : null}
          </Col>
          <Col md={4} className="bg-light">
            {!selectedSegment ? <HowItWorks /> : null}
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default CustomCampaignsModal
