import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { CampaignStateT, Fields as Campaign } from '../models/Campaign'
import orm from '../models/orm'
import { selectCampaignTypesByCategory } from './campaignType'

export const selectCampaigns = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Campaign[] => {
    return session.Campaign.all().toRefArray()
  }
)

export const selectCampaign = createSelector(
  selectCampaigns,
  (_: any, id: number): number => id,
  (campaigns, id): Campaign | null =>
    campaigns.find(c => c.id === Number(id)) ?? null
)

export const selectCampaignsByCategory = createSelector(
  selectCampaigns,
  (state: any, category: string) =>
    selectCampaignTypesByCategory(state, category),
  (campaigns, campaignTypes): Campaign[] => {
    const types = campaignTypes.map(t => t.type)
    return campaigns.filter(campaign => types.includes(campaign.type))
  }
)

// Returns campaigns that are able to have reports
// which excludes loyalty and intro programs.
export const selectCampaignsWithReports = createSelector(
  selectCampaigns,
  (campaigns): Campaign[] => {
    return campaigns.filter(c => c.type !== 'intro' && c.type !== 'loyalty')
  }
)

export const selectReportableCampaignsByState = createSelector(
  (state: any) => selectCampaignsWithReports(state),
  (_: any, campaignState: CampaignStateT) => campaignState,
  (campaigns, campaignState): Campaign[] =>
    campaigns.filter(c => c.state === campaignState)
)

export const selectCampaignsByTemplateId = createSelector(
  selectCampaigns,
  (_: any, templateId: number): number => templateId,
  (campaigns, templateId): Campaign[] => {
    return campaigns.filter(c => c.redeem_template_id === templateId)
  }
)

export const selectCampaignsByCategoryAndState = createSelector(
  (state: any, category: string): Campaign[] =>
    selectCampaignsByCategory(state, category),
  (_: any, category: string) => category,
  (_: any, _category: string, campaignState: CampaignStateT) => campaignState,
  (campaigns, _category, campaignState): Campaign[] => {
    return campaigns.filter(c => c.state === campaignState)
  }
)

export const selectCampaignsByType = createSelector(
  selectCampaigns,
  (_, type): string => type,
  (campaigns, type): Campaign[] => {
    return campaigns.filter(campaign => campaign.type === type)
  }
)

export const selectCampaignsByTypeAndState = createSelector(
  (state: any, type: string): Campaign[] => selectCampaignsByType(state, type),
  (_: any, type: string) => type,
  (_: any, type, state: CampaignStateT) => state,
  (campaigns, type, state): Campaign[] =>
    campaigns.filter(c => c.state === state)
)
