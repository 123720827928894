import { api } from './index'

export const Role = {
  admin: 'admin',
  manager: 'manager',
  approver: 'approver',
  accountant: 'accountant',
  customer_service_read: 'customer_service_read',
  customer_service_write: 'customer_service_write',
  mall: 'mall',
} as const

// @ts-ignore
export type Role = typeof Role[keyof typeof Role]

export type MerchantUser = {
  id: number
  first_name: string
  last_name: string
  email: string
  pending: boolean
  role: Role
  location_ids: number[]
}

type GetMerchantUsersResponse = { merchant_users: MerchantUser[] }

type GetMerchantUserResponse = { merchant_user: MerchantUser }

type GetMerchantUsersParams = {
  roles?: Role[]
}

export type CreateMerchantUserPayload = {
  email: string
  role: Role
  location_ids?: number[]
}

export type UpdateMerchantUserPayload = Pick<
  MerchantUser,
  'id' | 'role' | 'location_ids'
>

export const merchantUserTags = ['MerchantUsers']

export const merchantUsersApi = api.injectEndpoints({
  endpoints: builder => ({
    getMerchantUsers: builder.query<
      GetMerchantUsersResponse,
      GetMerchantUsersParams
    >({
      query: params => {
        const searchParams = new URLSearchParams()
        params.roles?.forEach(role => searchParams.append('roles', role))

        return {
          url: 'merchant_users',
          params: searchParams,
        }
      },
      providesTags: ['MerchantUsers'],
    }),

    getMerchantUser: builder.query<GetMerchantUserResponse, string>({
      query: id => ({ url: `merchant_users/${id}` }),
      providesTags: ['MerchantUsers'],
    }),

    createMerchantUser: builder.mutation<
      MerchantUser,
      CreateMerchantUserPayload
    >({
      query: payload => ({
        url: 'merchant_users',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['MerchantUsers'],
    }),

    updateMerchantUser: builder.mutation<
      MerchantUser,
      Partial<UpdateMerchantUserPayload>
    >({
      query: patch => {
        const { id, ...rest } = patch
        return {
          url: `merchant_users/${id}`,
          method: 'PATCH',
          body: rest,
        }
      },
      invalidatesTags: ['MerchantUsers'],
    }),

    updateMe: builder.mutation<
      MerchantUser,
      Omit<UpdateMerchantUserPayload, 'id'>
    >({
      query: patch => ({
        url: 'merchant_users/me',
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['MerchantUsers'],
    }),

    deleteMerchantUser: builder.mutation<void, number>({
      query: id => ({
        url: `merchant_users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MerchantUsers'],
    }),
  }),
})
