import appearanceHowTo from 'assets/images/redeem-manager/thumbnail_appearance.png'
import hiddenMenuHowTo from 'assets/images/redeem-manager/thumbnail_hidden_menu.png'
import instoreHowTo from 'assets/images/redeem-manager/thumbnail_instore.png'
import { buildTranslate } from 'locales'
import {
  bonusPointsHelpUrl,
  customImageManualUrl,
  customImageUrl,
  goldenTicketHelpUrl,
  hiddenMenuHelpUrl,
  hiddenMenuOloHelpUrl,
  restrictionsHelpUrl,
} from 'utilities/urlUtils'

const t = buildTranslate('redeem_manager.builder.steps.configuration.help')

export const getHelp = type => {
  switch (type) {
    case 'hidden_menu': {
      return [
        {
          title: t('hidden_menu_help_1'),
          url: hiddenMenuHelpUrl,
        },
        {
          title: t('hidden_menu_help_2'),
          url: hiddenMenuOloHelpUrl,
        },
        {
          title: t('restrictions_help'),
          url: restrictionsHelpUrl,
        },
        {
          title: t('custom_image'),
          url: customImageUrl,
        },
        {
          title: t('custom_manual_image'),
          url: customImageManualUrl,
        },
      ]
    }
    case 'golden_ticket': {
      return [
        {
          title: t('golden_ticket_help_1'),
          url: goldenTicketHelpUrl,
        },
        {
          title: t('restrictions_help'),
          url: restrictionsHelpUrl,
        },
        {
          title: t('custom_image'),
          url: customImageUrl,
        },
        {
          title: t('custom_manual_image'),
          url: customImageManualUrl,
        },
      ]
    }
    case 'bonus_points': {
      return [
        {
          title: t('get_bonus_points'),
          url: bonusPointsHelpUrl,
        },
        {
          title: t('restrictions_add_help'),
          url: restrictionsHelpUrl,
        },
      ]
    }
    default:
      return [
        {
          title: t('restrictions_help'),
          url: restrictionsHelpUrl,
        },
        {
          title: t('custom_image'),
          url: customImageUrl,
        },
      ]
  }
}

export const getHowTo = {
  appearance: appearanceHowTo,
  instore: instoreHowTo,
  hiddenMenu: hiddenMenuHowTo,
}
