import { getCampaigns } from 'actions/campaigns'
import { getCampaignTypes } from 'actions/campaignTypes'
import { updatePaginationCurrentPage } from 'actions/pagination'
import { CampaignStateT } from 'models/Campaign'
import { CampaignPaginationT } from 'models/Pagination'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectReportableCampaignsByState } from 'selectors/campaign'
import { selectPagination } from 'selectors/pagination'

export const useReportFetcher = () => {
  const reviewCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'in_review')
  )
  const scheduledCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'scheduled')
  )
  const activeCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'active')
  )
  const draftCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'draft')
  )
  const completeCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'complete')
  )

  const [reviewIsLoading, setReviewIsLoading] = useState<boolean>(
    reviewCampaigns.length === 0
  )
  const [scheduledIsLoading, setScheduledIsLoading] = useState<boolean>(
    scheduledCampaigns.length === 0
  )
  const [activeIsLoading, setActiveIsLoading] = useState<boolean>(
    activeCampaigns.length === 0
  )
  const [draftIsLoading, setDraftIsLoading] = useState<boolean>(
    draftCampaigns.length === 0
  )
  const [completeIsLoading, setCompleteIsLoading] = useState<boolean>(
    completeCampaigns.length === 0
  )
  const dispatch = useDispatch()

  const fetchData = useCallback(
    async (state: CampaignStateT) => {
      await dispatch(getCampaigns({ state: state, page: 1 }))
      handleResults(state)
    },
    [dispatch]
  )

  function handleResults(state) {
    switch (state) {
      case 'active':
        setActiveIsLoading(false)
        break
      case 'in_review':
        setReviewIsLoading(false)
        break
      case 'draft':
        setDraftIsLoading(false)
        break
      case 'complete':
        setCompleteIsLoading(false)
        break
      case 'scheduled':
        setScheduledIsLoading(false)
        break
      default:
        break
    }
  }

  useEffect(() => {
    async function fetchTypes() {
      await dispatch(getCampaignTypes())
    }

    fetchTypes()
  }, [dispatch])

  useEffect(() => {
    fetchData('draft')
    fetchData('active')
    fetchData('complete')
    fetchData('scheduled')
    fetchData('in_review')
  }, [fetchData])

  // this handles a corner case where a tab is still loading and the user
  // navigates away and then comes back. without this, it spins forever.
  useEffect(() => {
    if (reviewCampaigns.length !== 0) setReviewIsLoading(false)
    if (scheduledCampaigns.length !== 0) setScheduledIsLoading(false)
    if (activeCampaigns.length !== 0) setActiveIsLoading(false)
    if (draftCampaigns.length !== 0) setDraftIsLoading(false)
    if (completeCampaigns.length !== 0) setCompleteIsLoading(false)
  }, [
    reviewCampaigns.length,
    scheduledCampaigns.length,
    activeCampaigns.length,
    draftCampaigns.length,
    completeCampaigns.length,
  ])

  return {
    review: {
      campaigns: reviewCampaigns,
      loading: reviewIsLoading,
    },
    scheduled: {
      campaigns: scheduledCampaigns,
      loading: scheduledIsLoading,
    },
    active: {
      campaigns: activeCampaigns,
      loading: activeIsLoading,
    },
    draft: {
      campaigns: draftCampaigns,
      loading: draftIsLoading,
    },
    complete: {
      campaigns: completeCampaigns,
      loading: completeIsLoading,
    },
  }
}

export const useReportPagination = (state: CampaignStateT) => {
  const dispatch = useDispatch()
  const [isFetchingPage, setIsFetchingPage] = useState(false)
  const paginationId: CampaignPaginationT['id'] = `${state}-all`
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'campaign', paginationId)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const campaignIds = paginationInfo?.record_ids?.[currentPage] ?? []

  const onChangePage = useCallback(
    async page => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // campaigns for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'campaign',
            id: paginationId,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      setIsFetchingPage(true)
      await dispatch(getCampaigns({ state: state, page: page }))
      setIsFetchingPage(false)
    },
    [state, dispatch, paginationInfo]
  )

  return {
    isFetchingPage,
    currentPage,
    onChangePage,
    numPages,
    campaignIds,
  }
}
