import React from 'react'
import { render } from 'react-dom'
import Cookie from 'js-cookie'
import store from './store'
import * as merchantUserActions from 'actions/merchantUser'
import { getMerchant } from 'actions/merchant'
import * as authActions from 'actions/auth'
import { updateRedirect } from 'actions/redirect'
import App from './scenes/App'
// $FlowFixMe
import urlUtils from 'utilities/urlUtils'
import { push } from 'react-router-redux'
import { I18n } from 'react-redux-i18n'
import ReactGA from 'react-ga'
import AppProviders from 'components/AppProviders'
import './index.scss'
import { isLegalPage } from 'utilities/pages'

if (process.env.REACT_APP_GOOGLE_ANALYTICS_UID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UID)
}

require('utilities/formsyValidators')
require('typeface-lato')

if (process.env.REACT_APP_SENTRY_DSN) {
  window.Raven.config(process.env.REACT_APP_SENTRY_DSN).install()
}

I18n.setHandleMissingTranslation(() => undefined)

let passwordAuth = Cookie.getJSON('thanx-auth-password') || {}
let isAuthPage = urlUtils.matchPath('/auth', false)

if (passwordAuth.access_token && passwordAuth.current_merchant_user_id) {
  store.dispatch({
    ...passwordAuth,
    type: authActions.STORE_AUTH_DATA,
  })

  if (passwordAuth.current_merchant_id) {
    Promise.all([
      store.dispatch(merchantUserActions.getCurrentMerchantUser(store)),
      store.dispatch(getMerchant(passwordAuth.current_merchant_id, store)),
    ])
  } else {
    store.dispatch(push('/choose'))
  }
} else if (!isAuthPage && !isLegalPage()) {
  store.dispatch(updateRedirect(window.location.pathname))
  store.dispatch(push('/auth/login'))
}

render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
)

const browserNav = window.navigator
if (typeof browserNav !== 'undefined') {
  if ('serviceWorker' in browserNav) {
    browserNav.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}
