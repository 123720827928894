import { Text } from '@thanx/uikit/text'
import { CreateMerchantUserPayload, Role } from 'api/merchantUsers'
import Formsy from 'formsy-react'
import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'
import { buildTranslate } from '../../locales'
import EmailCard from './EmailCard'
import FormLayout from './FormLayout'
import MerchantUserForm from './MerchantUserForm'

const t = buildTranslate('merchant_users.form.access')

const NewMerchantUser = () => {
  const [merchantUser, setMerchantUser] = useState<CreateMerchantUserPayload>({
    email: '',
    role: 'admin',
    location_ids: [],
  })

  const [css] = useStyletron()

  return (
    <FormLayout onFinish={() => {}} canProceed={true}>
      <Formsy role="form">
        <EmailCard
          value={merchantUser.email}
          onChange={value => {
            setMerchantUser(prev => ({ ...prev, email: value }))
          }}
        />
        <Text.Header3
          className={css({
            marginTop: '40px',
            marginBottom: '20px',
          })}
        >
          {t('header')}
        </Text.Header3>
        <MerchantUserForm
          merchantUser={merchantUser}
          onChange={nextMerchantUser => {
            setMerchantUser(prev => ({
              ...prev,
              location_ids: nextMerchantUser.location_ids,
              role: nextMerchantUser.role as Role,
            }))
          }}
        />
      </Formsy>
    </FormLayout>
  )
}

export default NewMerchantUser
