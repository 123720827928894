export default {
  save_exit: 'Save & Exit',
  activate: 'Activate',
  send_a_test: 'Send a test',
  bonus_points_card: {
    title: 'Number of bonus points',
    label: 'How many bonus %{currencyName} customers get',
  },
  description_card: {
    title: 'Reward title',
    label: 'Text customers see',
    label_info:
      'Enter the text your customers will see when they view this reward in the app or in your email.',
    get_a_an: 'Get a/an',
    get: 'Get',
    required_error: 'Reward text can’t be empty',
    min_length_error: 'Please enter at least %{minLength} characters',
    max_length_error:
      'Reward description cannot contain more than %{maxLength} characters',
    example: 'example',
    not_editable:
      'This field may not be editable after a campaign issues rewards using this template',
    add: 'Add description',
    optional: 'Optional description on the reward detail screen',
    remove: 'Remove description',
    optional_placeholder: 'Enter description',
  },
  discount_card: {
    title: 'Discount',
    discount_amount: 'Discount amount',
    discount_info_dollar:
      'For example, enter 10 if you want to give customers a $10 discount.',
    discount_info_percent:
      'For example, enter 10 if you want to give customers a 10% discount.',
    retail_price_label: 'Retail price',
    retail_price_info:
      'What you normally charge customers for this item. It’s the price they pay without any discounts or rewards.',
    dollar: '$',
    percent: '%',
    percent_must_be: 'Discount must be between 1% and 100%',
    limit_to_250$:
      'If you’re using statement credits then the maximum discount you can offer is $250',
  },
  item_value_card: {
    title: 'Cost of reward',
    subtitle: 'Configure the cost of this reward for reporting.',
    cost_to_business_label: 'Cost to business',
    cost_to_business_info:
      'What it costs your business to produce this item, including materials and labor.\nFor example, if you’re selling a burger, the cost to business would be the cost of the buns, meat, cheese, veggies.\nIf you don’t know this, a good rule of thumb is 30% of the retail price.',
    cost_to_business_info_v2:
      'What it costs your business to produce this item.\nFor example, if you’re selling a burger, the cost to the business would be the cost of the buns, meat, cheese, veggies.\nIf you don’t know this, a good rule of thumb is 50% of the retail price.',
    retail_price_label: 'Retail price',
    retail_price_info:
      'What you normally charge customers for this item. It’s the price they pay without any discounts or rewards.',
    dollar: '$',
  },
  online_redemption_card: {
    title: 'Online Redemption',
    info_text:
      'Enforce this discount for online purchases by configuring which online items it applies to.',
    will_be_able_to_redeem:
      'Customers will be able to redeem this reward online',
    discounted_label_fallback:
      'Enter product IDs for items that get discounted',
    discounted_label_free_item_fallback: 'Enter product IDs of free items',
    required_label: 'When a customer purchases any of these items',
    required_label_fallback:
      'Enter product IDs for the items a customer must buy',
    products_required: 'At least one product id must be entered',
    will_apply_lowest:
      "When a customer adds any of these items to their cart, they'll get a discount on the lowest priced one",
    will_apply_lowest_free_item:
      'If the customer has more than one of the items above, the free item will be the lowest priced item in their cart.',
    including_addons:
      'Discount will apply to the entire item cost, including add-ons. You can limit how big this discount can get by adding a maximum redemption amount when you use it in a campaign.',
    example: 'Example',
    example_description:
      'If your customer adds a $10 burger to their cart, but they order it with guac (+$3) and an extra patty (+$2), the discount will be off the $15 final cost of the item.',
    then_entire_purchase:
      "Then they'll get a discount off their entire purchase",
    not_redeemable: 'Not redeemable online',
    not_available:
      'Online redemption is only available when you have Thanx Ordering configured. ',
    contact_us: 'Contact us to learn more.',
    hidden_menu: {
      subtitle:
        'Customers can redeem this reward online (via online ordering or in-app ordering).',
      categories: 'Hidden categories',
      select_description:
        'Changes to your menu may take up to 2 hours to show up in Thanx.',
      category_name: 'Category name',
      category_ids: 'Category Ids (separate by commas)',
      search: 'Search',
      select_category: 'Select category for your hidden menu',
      menu_header: 'Menu header',
      menu_subtitle:
        'Customers will see this message at the top of the menu category in the app and web.',
      show_default: 'Show the default message',
      show_custom: 'Show a custom image',
      congrats: 'Congratulations!',
      unlocked_menu: "You've unlocked a secret menu.",
      no_categories: 'No hidden categories found',
      create_category: 'Create a hidden category with your ordering provider.',
      learn_more: 'Learn more',
      custom_img_descriptor_1:
        'The customer will see this image at the top (app) or left (web) of your menu.',
      custom_img_descriptor_2:
        '<a href="%{url}" target="_blank">Don’t forget to add text to your image</a> that describes that they unlocked a hidden menu.',
    },
  },
  instore_redemption_card: {
    title: 'In-store Redemption',
    info_desc:
      'Enforce this discount for in-store purchases by configuring how the discount links to the point of sale.',
    manager_comp_desc:
      'The customer opens the reward app and shows the reward to your team member. Your team member manually enters the discount into the point of sale.',
    manager_comp_unavailable:
      'Manager comp not available when in-store offer management is powered by integrations, e.g, Fishbowl, Northstar, etc.',
    coupon_codes_desc:
      'The customer opens the reward app and shows the reward to your team member. The reward has a barcode, QR code, or a coupon code. Your team member scans the code into the point of sale.',
    coupon_codes_enforced_by_pos_desc_1:
      'Manually managed coupon pools: The customer opens the reward app and shows the reward to your team member. The reward has a barcode, QR code, or a coupon code. Your team member scans the code into the point of sale.',
    coupon_codes_enforced_by_pos_desc_2:
      'Integrations between Thanx and the POS: Either Thanx generates a unique code for each customer at redemption. Or the customer identifies themselves at the counter and Thanx tells the POS which rewards are available. The experience depends on the integration.',
    coupon_codes_unavailable:
      'Coupon codes are not available for this type of reward.',
    coupon_codes_enforced_by_pos_unavailable:
      'Enforcement by POS is not available for this type of reward.',
    automatic_cash_back_desc:
      'The customer activates automatic cash back in the rewards app. Then they make purchases as usual and do not have to show the reward to a team member. The discount is automatically applied to the purchase as cash back on their credit card statement.',
    automatic_unavailable:
      'Automatic cashback is only available on discounts (e.g. 10% off) and not free items',
    type: {
      manager_comp: 'Manager comp',
      manager_comp_info:
        'Any restrictions must be enforced by a team member or POS at checkout.',
      manager_comp_url: {
        title: 'Where should this image link to?',
        no_url: 'Don’t link to anything',
        url: 'URL ',
        placeholder: 'Enter a valid URL',
      },
      coupon_codes: 'Coupon codes',
      coupon_codes_enforced_by_pos: 'Enforced by POS',
      automatic_cash_back: 'Automatic cash back',
    },
    custom_img_descriptor_1:
      'The customer will see this image after they tap on the reward in the app.',
    custom_img_descriptor_2:
      '<a href="%{url}" target="_blank">Don’t forget to add text to your image</a> that describes what the customer receives.',
    not_redeemable: 'Not redeemable in-store',

    coupon_codes: {
      type_label: 'Type',
      discounted_label:
        'Enter product IDs from your POS for items that get discounted',
      discounted_label_free_item:
        'Enter product IDs from your POS of free items',
      required_label_fallback:
        'Enter product IDs from your POS for items a customer must buy',
      then_entire_purchase:
        "Then they'll get a discount off their entire purchase",
      type_tooltip: {
        pool_title: 'Pool of unique codes',
        pool_content:
          'A long list of one-time use codes used to link the discount to your point of sale. Each customer that gets this reward will see a different coupon code. Each unique code expires after redemption.',
        reusable_title: 'Reusable static code',
        reusable_content:
          'A single code used to link the discount to your point of sale. All customers that get this reward will see the same code.',
        unique_title: 'Integration between Thanx and the POS',
        unique_content_1:
          'With the Toast integration Thanx generates a unique QR code that can be scanned at the POS. Alternatively, the customer can identify themselves at the POS (e.g., via phone or email) and Thanx tells the POS which rewards are available for that customer.',
        unique_content_2:
          'With other integrations (Fishbowl, Northstar, etc.) a unique code is generated for each customer at redemption. Each customer that gets this reward will see a different code. Each unique code expires after redemption.',
      },
      format_label: 'Format',
      format_tooltip:
        'How the code appears in the app when the customer redeems the reward. Select the format that your point of sale’s scanner can process (eg. barcode, QR code, etc.). Select raw if the code needs to be entered manually.',
      static_code_label: 'Coupon code',
      static_code_tooltip:
        'A single code used to link the discount to your point of sale. All customers that get this reward will see the same code. This code is usually generated by your point of sale.',
      type: {
        static: 'Reusable static code',
        variable: 'Pool of unique codes',
        dynamic: 'Integration between Thanx and the POS',
      },
      format: {
        raw: 'raw',
        qrcode: 'qrcode',
        barcode39: 'barcode39',
        barcode39extended: 'barcode39extended',
        barcode93: 'barcode93',
        barcode128: 'barcode128',
        barcode_upc_a: 'barcode_upc_a',
        barcode_ean_8: 'barcode_ean_8',
        barcode_ean_13: 'barcode_ean_13',
        barcode25interleaved: 'barcode25interleaved',
      },

      upload: {
        upload_label: 'Upload coupon codes',
        add_more_label: 'Add more coupon codes',
        description: `Import coupon codes from CSV file. You can <a href="${process.env.PUBLIC_URL}/coupon_codes_example.csv">download a sample CSV</a> file to see an example of the format required.`,
        tooltip:
          'A CSV file with list of one-time use codes used to link the discount to your point of sale. Each customer that gets this reward will see a different coupon code. Each unique code expires after redemption.',
        batches:
          'Upload codes in batches of 200,000 or less. Larger files may take longer to upload.',
        uploads_list: {
          title: 'Imported coupon codes',
          processing: 'Coupon code import in progress',
          errored: 'Coupon codes failed to import on %{date}',
          finalized: '%{count} coupon codes imported on %{date}',
        },

        table_label: 'Preview of %{filename}',
        column_label: 'Which column has the codes',
        column_option: 'Column %{count}',
        upload_button: 'Upload CSV file',
        continue_button: 'Continue importing',
        cancel_button: 'Cancel',

        processing_1:
          'Your coupon codes are being processed. We’ll send you an email when the import is complete. This process may take a while.',
        processing_2:
          'You can close this page by clicking the save & exit button above and come back later when the import is done.',
        processing_2_template_detail:
          'You can close this page and come back later when the import is done.',

        success: '%{count} codes imported from %{filename}',
        success_with_date: '%{count} codes imported on %{date}',

        error:
          'Failed to upload. This could be caused by a poor internet connection. Try uploading codes in batches of 50,000 or less. Still not working? <a href="mailto: merchant.support@thanx.com">Contact us</a>',
        parse_error:
          'Something went wrong while parsing your file. Please check your file content and try again. <br/> Still not working? <a href="mailto: merchant.support@thanx.com">Contact us</a>',

        table: {
          header: 'Column %{count}',
        },
        format_label: 'Format',
      },
      dynamic: {
        integration: 'Integration between Thanx and the POS',
        entered_by_customer:
          'Customer enters their phone number or email at the POS.<br/>Then Thanx tells the POS which rewards are available to redeem for that customer.',
        issued_5_digit_code:
          'Each customer will be issued a unique 5 digit code at redemption.',
        disabled:
          'Thanx tells the POS which rewards are available to redeem for each customer.',
        requires_integration: 'This option requires an integration.',
        learn_more: 'Learn more',
        sample_code: 'Sample code',
        example: '12345',
        contact_us: 'Contact us to learn more.',
      },
      associated_coupon_pools: 'Coupon pools associated with this template',
    },
    image_crop:
      'Placing important graphics outside of the safe area may cause them to be cropped off or covered.',
  },
  images: {
    app: 'App',
    app_only: 'App Only',
    web: 'Web',
    online_size: '860px wide',
    index_size: '1,125 x 525px',
    instore_size: '1,284 x 2,780px',
    jpg: 'jpg',
    png: 'png',
  },
  customer_support_card: {
    title: 'Customer support',
    available_to_grant:
      'Available to grant to an individual customer when responding to customer feedback or providing customer support.',
    not_available_to_grant:
      'Not available to grant to an individual customer when responding to customer feedback or providing customer support.',
  },
  appearance_card: {
    title: 'Reward appearance',
    subtitle: 'Choose how customers will see this reward in the app.',
    show_default: 'Use the default reward appearance',
    show_custom: 'Use a custom image for the reward',
    redeem: 'Redeem',
    disabled:
      'This reward will apply instantly the next time customers sign in to the app or web experience. Customers will not see it in the reward list.',
    preview_disabled_1: 'This reward will apply instantly.',
    preview_disabled_2: 'Customers will not see it in the reward list.',
    replace_default:
      'This image will replace the reward text and icon from the default view.',
    custom_img_descriptor_1:
      'The customer will see this image instead of the reward title.',
    custom_img_descriptor_2:
      '<a href="%{url}" target="_blank">Don’t forget to add text to your image</a> that describes what the customer receives.',
  },
  restrictions: {
    title: 'Restrictions',
    content1:
      'Choose how customers engage with this reward when you create a campaign.',
    content2:
      "When you use this reward in a campaign you'll have the option to choose which customers receive it and add a number of restrictions such as min spend, max discount, customize fine print, and more.",
  },
  help: {
    title: 'Help',
    restrictions_help: 'About reward restrictions',
    restrictions_add_help: 'How to add restrictions to rewards',
    hidden_menu_help_1: 'How a hidden menu works',
    hidden_menu_help_2: 'How to configure a hidden menu in Olo',
    golden_ticket_help_1: 'How customers redeem this reward',
    custom_image: 'How to display a reward as a custom image',
    custom_manual_image: 'How to use a custom image for manual redemption',
    get_bonus_points: 'How customers can get bonus points',
  },
  how_to: {
    title: 'How to create this image',
    explainer: 'Explainer video',
    article: 'Help article',
  },
}
