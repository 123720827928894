import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import {
  getOrderingConfigs,
  putOrderingStatuses,
} from 'actions/ordering_configs'
import Help from 'components/Help'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as OrderingConfig } from 'models/OrderingConfig'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { selectOrderingConfigs } from 'selectors/orderingConfigs'
import { enableDisableLocations, orderingManagement } from 'utilities/urlUtils'
import LocationList from '../LocationList'
import ActionModal from './ActionModal'

type SelectionAction = 'enable' | 'disable' | null

const t = buildTranslate('ordering_management.location_list')

const Landing: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const orderingConfigs = useSelector(selectOrderingConfigs)
  const [selectedLocations, setSelectedLocations] = useState<OrderingConfig[]>(
    []
  )
  const [selectionAction, setSelectionAction] = useState<SelectionAction>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getOrderingConfigs())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])

  const handleLocationSelect = (location: OrderingConfig, checked: boolean) => {
    if (checked) {
      setSelectedLocations([...selectedLocations, location])
    } else {
      setSelectedLocations(
        selectedLocations.filter(l => {
          return l.id !== location.id
        })
      )
    }
  }

  const handleLocationSelectAll = () => {
    setSelectedLocations(
      orderingConfigs.filter(c => {
        return c.ordering_status !== 'not_configured'
      })
    )
  }

  const handleLocationSelectNone = () => {
    setSelectedLocations([])
  }

  const handleSubmit = (action: SelectionAction) => {
    setSelectionAction(action)
  }

  const handleSubmitConfirm = async () => {
    setIsSubmitting(true)
    if (selectionAction) {
      await dispatch(
        putOrderingStatuses(
          selectionAction,
          selectedLocations.map(l => l.id)
        )
      )
    }
    setIsSubmitting(false)
    setSelectionAction(null)
    dispatch(
      alert({
        key: 'success',
        message: t(`alert.${selectionAction}_success`),
        timeout: 5,
      })
    )
  }

  const handleAction = (config: OrderingConfig, action) => {
    setSelectedLocations([config])
    setSelectionAction(action)
  }

  return (
    <div className="w-100 h-100 pt-m pb-xxl px-0 white-bkg border-bottom-1 grey-20-border">
      <Container className="pt-m pb-xl">
        <div className="d-flex justify-content-between">
          <Text.Header2 className="m-0 mr-xl">{t('title')}</Text.Header2>
          <div>
            <Button
              kind={Kind.SECONDARY}
              disabled={!selectedLocations.length}
              onClick={() => handleSubmit('enable')}
            >
              {t('enable_selected_locations')}
            </Button>
            <Button
              kind={Kind.PRIMARY}
              className="ml-m"
              disabled={!selectedLocations.length}
              onClick={() => handleSubmit('disable')}
            >
              {t('disable_selected_locations')}
            </Button>
          </div>
        </div>
      </Container>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border pt-xl ${css({
          minHeight: 'calc(100% - 75px)',
        })}`}
      >
        <Container>
          <Text.Header3
            bold
            className={css({
              fontWeight: 900,
            })}
          >
            {t('locations')}
          </Text.Header3>
          <Text.BodyText3 color="grey70">{t('subtitle')}</Text.BodyText3>
          <Spinner isLoading={isLoading}>
            <LocationList
              className="mt-m"
              selectedLocations={selectedLocations}
              locations={orderingConfigs}
              onSelect={handleLocationSelect}
              onSelectAll={handleLocationSelectAll}
              onSelectNone={handleLocationSelectNone}
              onAction={handleAction}
            />
          </Spinner>
          <ActionModal
            isOpen={!!selectionAction}
            isSubmitting={isSubmitting}
            selectionAction={selectionAction}
            onClose={() => setSelectionAction(null)}
            onConfirm={handleSubmitConfirm}
          />
          <Help
            title={t('help.title')}
            articles={[
              {
                title: t('help.enable_disable_locations'),
                url: enableDisableLocations,
              },
              {
                title: t('help.description'),
                url: orderingManagement,
              },
            ]}
          />
        </Container>
      </div>
    </div>
  )
}

export default Landing
