export default {
  sms_keywords: {
    title: 'SMS keywords',
    description:
      'Customers can join your loyalty program via SMS when they text these keywords to 43618.<br /><br />Need to add more SMS keywords? <a href="mailto:merchant.support@thanx.com" target="_blank">Contact us</a>',
  },
  signup_message: {
    title: 'Sign-up message',
    description:
      'Your customers see this message when they first enter your sign-up flow in the app or on the web. Tell them why they should join your loyalty program (in 100 characters or less).<br /><br />Need to update your message? <a href="mailto:merchant.support@thanx.com" target="_blank">Contact us</a>',
    where_customers: 'Where customers see this',
  },
  onboarding_slideshow: {
    title: 'Onboarding slideshow',
    content: {
      title: 'Content',
      subtitle: 'Onboarding slideshow – Content',
      description:
        'Customer will see this slideshow if they are not logged in when they open your app. Use this opportunity to get them excited about using your app to place orders. You can make each slide look like anything you want. Learn more',
      badges: {
        app_only: 'app only',
        resolution: '1284 X 2780PX',
        three_mb: '3mb or smaller',
        jpg: 'jpg',
        png: 'png',
      },
      footer: {
        title: 'GO TO HOME PAGE',
        subtitle:
          'Customers will be taken to the home page when they tap on the last slide.',
        description:
          'If the last slide is a smart slide, e.g., a slide that prompts for Push notifications, then customers will be taken to the home page after they’ve completed the action required by the smart slide.',
      },
      explainer_banner: 'How to create a slide',
      empty_state: {
        title: 'There are no slides in this slideshow',
        description:
          'Customers will be automatically redirected to the Home page of your app.',
      },
      draft_banner: {
        title: 'This slideshow is in draft mode.',
        description:
          'Contact us to set this slideshow as the onboarding experience for your app.',
        contact_us: 'Contact us',
        save_draft: 'Save draft',
        email_subject: 'I would like to setup my onboarding slideshow',
      },
      frequency: {
        header: 'How many times customers see this slideshow',
        radio_buttons: {
          multiple: {
            header: 'Multiple times',
            description:
              'Logged out customers see this slideshow each time they open the app. Logged in customers will not see this slideshow.',
          },
          once: {
            header: 'Exactly once',
            description:
              'Logged out customers see this slideshow the first time they open the app, but they will not see this slideshow again even if they are still logged out. Logged in customers will not see this slideshow.',
          },
        },
      },
      block_item: {
        header: 'Slides',
        title: 'Slide %{index}',
        type: 'Slide type',
        next_slide_note:
          'Customer will move to the next slide when they either swipe right, tap on the right carousel arrow, or tap anywhere on this slide.',
        auth_slide_note: 'Customer will be prompted to sign up or log in.',
        auth_slide_restrictions:
          'This slide is only visible if the customer is not logged in.',
        push_slide_note:
          'This slide is only visible if the customer has not interacted with their device’s Push notifications prompt before.',
        push_slide_behavior:
          'Customer will be prompted to grant permission to receive Push notifications when they tap anywhere on this slide.',
        file_size_restrictions: 'File size must be 3MB or smaller',
        options: {
          simple: 'Simple image slide',
          push_notification: 'Smart slide - Push notifications',
          authentication: 'Smart slide - Sign up / Log in',
        },
        accessibility: {
          title: 'Accessibility',
          description:
            'Screen readers require this content to meet accessibility standards.',
          content: 'Slide content',
          button: 'Button description',
          defaults: {
            simple: {
              content: 'A graphic welcoming you to the app.',
              button: 'Tap to continue.',
            },
            push_notification: {
              content:
                'Enable push notification to learn when you earn something awesome!',
              button: 'Tap to enable push notification.',
            },
          },
        },
      },
    },
    style: {
      title: 'Style',
      subtitle: 'Onboarding slideshow – Style',
      description:
        'Customer will see this slideshow if they are not logged in when they open your app. Use this opportunity to get them excited about using your app to place orders. You can make each slide look like anything you want. Learn more',
      controller_style: {
        title: 'Slideshow controller style',
        enabled_arrow_color: 'Enabled arrow color',
        disabled_arrow_color: 'Disabled arrow color',
        active_dot_color: 'Active dot color',
        inactive_dot_color: 'Inactive dot color',
      },
    },
    submit_error: 'This page could not be saved. Please try again.',
    submit_success: 'Your changes to your onboarding slideshow are saved.',
    sidebar: {
      publish: 'Publish slideshow',
      save_draft: 'Save draft',
      onboarding_slideshow_preview: 'Onboarding slideshow preview',
      modal: {
        publish_changes: 'Publish changes',
        you_are_publishing:
          'You are publishing changes to <strong>Onboarding slideshow</strong><br/>Publishing these changes will push them live to your app.',
        publish: 'Yes, publish changes',
      },
    },
  },
}
