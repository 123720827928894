import {
  GET_MERCHANTS_SUCCESS,
  GET_MERCHANT_SUCCESS,
  ResolvedAction,
} from 'actions/merchant'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'

import { ImageUrlsT } from 'utilities/types'

type MerchantSettingsT = {
  visit: boolean
}

export interface Fields {
  id: number
  app_primary_image_urls: ImageUrlsT
  app_secondary_image_urls: ImageUrlsT
  automatic_capable: boolean
  average_check_size: number
  branded_app: boolean
  exclusive_deals: boolean
  cover_image_urls: ImageUrlsT
  custom_campaigns_enabled: boolean
  handle: string
  intro_description: string
  logo_image_urls: ImageUrlsT
  reward_icon_urls: ImageUrlsT | null
  loyalty_description: string
  primary_color: string | null
  merchant_type: string
  name: string
  nickname: string
  ordering_enabled: boolean
  point_of_sale: string
  processor: string
  purchase_volume: number
  state: string
  user_count: number
  live_at: string
  settings: MerchantSettingsT
  support_owner: 'customer_success' | 'support'
  online_only: boolean
  app_ordering_enabled: boolean
  web_ordering_enabled: boolean
  ordering_provider?: 'olo' | 'toast' | null
  supports_loyalty_integration: boolean
  loyalty_integration_partner:
    | 'toast'
    | 'revel'
    | 'fishbowl'
    | 'northstar'
    | null
  custom_onboarding_enabled: boolean
  loyalty_transition_settings: {
    eligible: boolean
    type: 'phone' | 'email' | 'license'
  }
  support_managed_by: 'thanx' | 'merchant'
}

// @ts-ignore
export default class Merchant extends Model<typeof Merchant, Fields> {
  static modelName: string = 'Merchant'

  static get fields(): any {
    return {
      id: attr(),
      name: attr(),
      handle: attr(),
      state: attr(),
      processor: attr(),
      point_of_sale: attr(),
      average_check_size: attr(),
      purchase_volume: attr(),
      automatic_capable: attr(),
      user_count: attr(),
      logo_image_urls: attr(),
      cover_image_urls: attr(),
      app_primary_image_urls: attr(),
      loyalty_description: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Merchant: ModelType<Merchant>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_MERCHANTS_SUCCESS:
        action.payload.data.merchants.forEach(merchant => {
          Merchant.upsert(merchant)
        })
        break
      case GET_MERCHANT_SUCCESS:
        Merchant.upsert(action.payload.data.merchant)
        break
      case RESET_DB:
        Merchant.all().delete()
        break
      default:
        break
    }
  }
}
