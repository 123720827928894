import { api } from './index'

export type LegalMerchant = {
  name: string
}

export const legalApi = api.injectEndpoints({
  endpoints: builder => ({
    getLegalMerchants: builder.query<{ merchants: LegalMerchant[] }, void>({
      query: () => ({
        url: `/legal`,
        method: 'GET',
      }),
      providesTags: ['LegalMerchants'],
    }),
  }),
})
