import {
  ContentBlockTypes,
  LandingPageTheme,
  LandingPageThemeUpdatePayload,
} from 'api/landingPageTheme'
import palette from 'constants/palette'
import { UrlType } from 'scenes/Cms/components/UrlPicker'
import {
  ContentBlockData,
  FormModel,
} from 'scenes/Cms/Content/AppHomePage/hooks'

export const getDefaultBlockData = (
  type: ContentBlockTypes,
  primaryColor: string
): ContentBlockData | null => {
  const image = {
    id: null,
    url: '',
    uploading: false,
  }
  const url = {
    inapp: '',
    external: '',
    type: UrlType.External,
    authenticable: false,
  }

  switch (type) {
    case 'featured_content':
      return {
        type,
        settings: {
          padding_top: 0,
          padding_right: 0,
          padding_bottom: 0,
          padding_left: 0,
          border_radius: 0,
          border_width: 0,
          border_color: palette.white,
          url: '',
        },
        image,
        url,
      }
    case 'stored_value_balance':
      return {
        type,
        settings: {
          padding_top: 12,
          padding_right: 12,
          padding_bottom: 12,
          padding_left: 12,
          border_radius: 2,
          border_width: 1,
          border_color: palette.grey30,
          background_color: palette.grey10,
          header_text_color: palette.grey90,
          body_text_color: palette.grey70,
          chevron_color: palette.grey90,
        },
      }
    case 'missing_loyalty_prompt':
      return {
        type,
        settings: {
          padding_top: 0,
          padding_right: 0,
          padding_bottom: 0,
          padding_left: 0,
          border_radius: 0,
          border_width: 0,
          border_color: palette.white,
          background_color: palette.grey10,
          header_text_color: palette.grey90,
          body_text_color: palette.grey70,
          button_border_color: primaryColor,
          button_text_color: primaryColor,
          close_icon_color: palette.black,
        },
      }
    case 'rewards':
      return {
        type,
        settings: {
          padding_top: 14,
          padding_right: 12,
          padding_bottom: 14,
          padding_left: 12,
          background_color: palette.white,
          border_color: palette.grey30,
          border_width: 1,
          border_radius: 1,
          header_text_color: palette.grey90,
          body_text_color: palette.grey90,
          item_spacing: 8,
          button_border_color: primaryColor,
          button_text_color: primaryColor,
          link_color: primaryColor,
        },
      }
    case 'recent_orders':
      return {
        type,
        settings: {
          padding_top: 12,
          padding_right: 12,
          padding_bottom: 0,
          padding_left: 12,
          border_radius: 2,
          border_width: 1,
          border_color: palette.grey30,
          background_color: '#FFFFFF',
          header_text_color: palette.grey90,
          body_text_color: palette.grey50,
          item_spacing: 12,
          button_bg_color: primaryColor,
          button_text_color: palette.white,
        },
      }
    case 'menu_categories':
      return {
        type,
        settings: {
          padding_top: 12,
          padding_right: 12,
          padding_bottom: 0,
          padding_left: 12,
          border_radius: 2,
          border_width: 1,
          border_color: palette.grey30,
          background_color: palette.white,
          header_text_color: palette.grey90,
          item_spacing: 12,
        },
      }
    case 'external_form':
      return {
        type,
        settings: {
          padding_top: 0,
          padding_right: 0,
          padding_bottom: 0,
          padding_left: 0,
          border_radius: 0,
          border_width: 0,
          border_color: palette.white,
          form_id: undefined,
        },
        image,
      }
    default:
      return null
  }
}

export const hasImage = (type: ContentBlockTypes): boolean => {
  switch (type) {
    case 'featured_content':
    case 'external_form':
      return true
    default:
      return false
  }
}

type GetSubmitPayloadT = {
  blockOrder: Array<string>
  formModel: FormModel
  landingPageTheme: LandingPageTheme | null
}

export function getSubmitPayload({
  blockOrder,
  formModel,
  landingPageTheme,
}: GetSubmitPayloadT): LandingPageThemeUpdatePayload['landing_page_theme'] {
  const blockUpdates: LandingPageThemeUpdatePayload['landing_page_theme']['content_blocks'] =
    []

  blockOrder.forEach((blockId, index) => {
    const contentBlock = formModel.contentBlocks[blockId]
    const { type, settings } = contentBlock
    const block = (landingPageTheme?.content_blocks ?? []).find(
      b => `${b.id}` === blockId
    )

    const file_uploads = (() => {
      if (!('image' in contentBlock)) return
      const image = contentBlock.image

      if (!image?.id) return

      return {
        image: {
          file_upload_id: image.id,
        },
      }
    })()

    let urlAddress: string | undefined
    if ('url' in contentBlock) {
      const url = contentBlock.url
      if (url?.type === UrlType.NoLink) {
        urlAddress = ''
      } else {
        urlAddress = url?.[url.type]
      }
      if (
        url?.type === UrlType.Inapp &&
        url.inapp.startsWith('thanx://ordering-menu') &&
        url.queryParams?.split('category=')[1]
      ) {
        urlAddress += `?${url.queryParams}`
      }
    }
    const updatedSettings = {
      ...settings,
      ...(typeof urlAddress === 'string' ? { url: urlAddress } : {}),
    }

    blockUpdates.push({
      id: block?.id,
      position: index + 1,
      type,
      settings: updatedSettings,
      file_uploads,
    })
  })
  const deleteContentBlocks = (landingPageTheme?.content_blocks ?? [])
    .filter(({ id }) => blockOrder.indexOf(`${id}`) === -1)
    .map(({ id }) => ({
      id,
      destroy: true,
    }))

  return {
    id: landingPageTheme?.id,
    content_blocks: blockUpdates.concat(deleteContentBlocks),
    settings: formModel.settings,
  }
}

// Slug matches breact slug parser
export function getCategorySlug(categoryName: string): string {
  return categoryName.replace(/[^A-Za-z]+/g, '-').toLowerCase()
}
