import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import { getCouponPools } from 'actions/couponPools'
import { getAllFileUploads, SourcePath } from 'actions/fileUpload'
import { getRedeemTemplate } from 'actions/redeemTemplates'
import TooltipLabel from 'components/TooltipLabel'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  CouponPoolGenerationType,
  Fields as CouponPoolFields,
} from 'models/CouponPool'
import {
  CouponPoolFields as CouponPoolFileUpload,
  State as FileUploadState,
} from 'models/FileUpload'
import { Fields as Template } from 'models/RedeemTemplate'
import Papa, { ParseResult } from 'papaparse'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ModelContext } from 'scenes/RedeemManager/Builder/FormModel'
import useCouponPools from 'scenes/RedeemManager/Builder/useCouponPools'
import {
  selectUploadById,
  selectUploadsByCouponPoolId,
} from 'selectors/fileUpload'
import { useInterval } from 'web-api-hooks'
import ChooseFile from './ChooseFile'
import Processing from './Processing'
import SuccessNotification from './SuccessNotification'
import UploadFile from './UploadFile'
import UploadsList from './UploadsList'

enum Step {
  CHOOSE,
  UPLOAD,
  PROCESSING,
  SUCCESS,
}

const MAX_ROWS = 5

type Props = {
  className?: string
  couponPool?: CouponPoolFields
  template?: Template | null
  fromDetailsPage?: boolean
  formContext?: ModelContext
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.upload'
)

const PoolUploadForm: React.FC<Props> = props => {
  const {
    className = '',
    couponPool: defaultCouponPool,
    template,
    fromDetailsPage,
    formContext,
  } = props

  const { createCouponPool } = useCouponPools()
  const [couponPool, setCouponPool] = useState<CouponPoolFields | undefined>(
    defaultCouponPool
  )
  const fileUploads = useSelector(state => {
    const currentPool = defaultCouponPool ?? couponPool
    if (currentPool?.id) {
      const uploads = selectUploadsByCouponPoolId(state, currentPool.id)
      return uploads.filter(upload => upload.state !== FileUploadState.CREATED)
    }
    return []
  })
  const processingUploads = fileUploads.filter(
    u => u.state === FileUploadState.PROCESSING
  )
  const [fileUploadId, setFileUploadId] = useState<number | null>(
    processingUploads[processingUploads.length - 1]?.id ?? null
  )
  const fileUpload = useSelector(state =>
    fileUploadId === null
      ? null
      : (selectUploadById(state, fileUploadId) as CouponPoolFileUpload)
  )

  const [step, setStep] = useState<Step>(
    fileUploadId ? Step.PROCESSING : Step.CHOOSE
  )
  const [error, setError] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [csvContent, setCsvContent] = useState<string[][] | null>(null)
  const [poolCreated, setPoolCreated] = useState<boolean>(false)

  useEffect(() => {
    if (processingUploads.length > 0 && !fileUploadId) {
      setFileUploadId(processingUploads[processingUploads.length - 1].id)
      setStep(Step.PROCESSING)
    }
  }, [processingUploads, fileUploadId])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!couponPool?.id) return
    dispatch(getAllFileUploads(SourcePath.COUPON_POOLS, couponPool.id))
  }, [dispatch, couponPool])

  useEffect(() => {
    if (!fromDetailsPage && !!formContext && !!couponPool) {
      const { model: formModel, setModel } = formContext
      if (!!formModel?.selectedCouponPools && !poolCreated) {
        setModel({
          ...formModel,
          selectedCouponPools: [...formModel.selectedCouponPools, couponPool],
        })
        setPoolCreated(true)
      }
    }
  }, [dispatch, couponPool, formContext, poolCreated, fromDetailsPage])

  const hasProcessingUpload = processingUploads.length > 0

  // Poll the file uploads to see if they're still processing. We use a pretty
  // naive algorithm that just polls every 5 seconds.
  useInterval(
    () => {
      if (couponPool?.id) {
        dispatch(getAllFileUploads(SourcePath.COUPON_POOLS, couponPool.id))
      }
    },
    // Passing null clears the timer when there's nothing processing
    hasProcessingUpload ? 5000 : null
  )

  async function createPool() {
    if (template?.id) {
      const pool = await createCouponPool({
        templateId: template.id,
        generationType: CouponPoolGenerationType.VARIABLE,
      })
      if (!!pool) {
        setCouponPool(pool)
      }
    }
  }

  // TODO: Validate barcodes when column changes
  async function handleChooseFile(file: File) {
    if (template?.id) {
      await createPool()
      await Promise.all([
        dispatch(getCouponPools(template.id)),
        dispatch(getRedeemTemplate(template.id)),
      ])
    }

    // Parse the file
    // We always assume the first row is a header to avoid errors.
    // Losing one code isn't a big deal.
    Papa.parse(file, {
      header: false,
      preview: MAX_ROWS + 1,
      delimiter: ',',
      complete: (result: ParseResult<string[]>) => {
        if (result.errors.length > 0 || result.data.length === 0) {
          setError(t('parse_error'))
          return
        }
        setError(null)
        setCsvContent(result.data.slice(1))
        setFile(file)
        setStep(Step.UPLOAD)
      },
    })
  }

  function handleCancel() {
    setFile(null)
    setStep(Step.CHOOSE)
  }

  function handleContinue(fileUpload: CouponPoolFileUpload) {
    setFileUploadId(fileUpload.id)
    setStep(Step.PROCESSING)
  }

  function onProcessingComplete(currentStep: FileUploadState) {
    if (currentStep === FileUploadState.FINALIZED) {
      setStep(Step.SUCCESS)
    } else {
      setError(t('parse_error'))
      handleCancel()
    }
  }

  const header = (
    <div className="mb-m">
      <div className="body-text-4 grey-90 bold mb-xs">
        <TooltipLabel
          label={
            fileUploads.length > 0 ? t('add_more_label') : t('upload_label')
          }
          tooltip={t('tooltip')}
        />
      </div>
      <div
        className="body-text-4 grey-70 mb-s"
        dangerouslySetInnerHTML={{ __html: t('description') }}
      />
      <p className="body-text-4 grey-70 mb-xs">{t('batches')}</p>
      <Switch condition={!!error}>
        <div className="mb-m">
          <Notification kind="negative" size="full">
            <span
              className="body-text-4"
              dangerouslySetInnerHTML={{ __html: error ?? '' }}
            />
          </Notification>
        </div>
      </Switch>
    </div>
  )

  return (
    <div className={className}>
      <Switch condition={fileUploads.length > 0}>
        <UploadsList uploads={fileUploads} className="mb-s" />
      </Switch>

      <Switch condition={step === Step.CHOOSE}>
        {header}
        <ChooseFile onChoose={handleChooseFile} />
      </Switch>

      <Switch condition={step === Step.UPLOAD}>
        {header}
        {file && !!csvContent && !!template && !!couponPool && (
          <UploadFile
            file={file}
            couponPool={couponPool}
            template={template}
            csvContent={csvContent}
            onCancel={handleCancel}
            onContinue={handleContinue}
          />
        )}
      </Switch>

      <Switch condition={step === Step.PROCESSING}>
        {fileUpload && (
          <Processing
            onComplete={onProcessingComplete}
            fileUpload={fileUpload}
            fromDetailsPage={fromDetailsPage}
          />
        )}
      </Switch>

      <Switch condition={step === Step.SUCCESS}>
        {fileUpload && !!fileUpload.finalized_at && (
          <>
            <div className="mb-m">
              <SuccessNotification
                count={fileUpload.settings.row_count ?? 0}
                filename={file?.name}
                date={fileUpload.finalized_at}
              />
            </div>
            {header}
            <ChooseFile onChoose={handleChooseFile} />
          </>
        )}
      </Switch>
    </div>
  )
}

export default PoolUploadForm
