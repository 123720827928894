import ProtectedRoute from 'components/ProtectedRoute'
import useFlag from 'hooks/useFlag'
import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import EditMerchantUser from './EditMerchantUser'
import Landing from './Landing'
import NewMerchantUser from './NewMerchantUser'

const MerchantUsers = () => {
  const manageDashboardAccess = useFlag(
    'merchant-ui-manage-dashboard-access',
    false
  )

  return (
    <Switch>
      {manageDashboardAccess ? (
        <>
          <ProtectedRoute
            redirect="/"
            resource="MerchantUsers"
            component={Landing}
            path="/merchant_users"
            exact
          />
          <ProtectedRoute
            redirect="/"
            resource="MerchantUsers"
            component={NewMerchantUser}
            path="/merchant_users/new"
            exact
          />
          <ProtectedRoute
            redirect="/"
            resource="MerchantUsers"
            component={EditMerchantUser}
            path="/merchant_users/edit/:id"
            exact
          />
        </>
      ) : (
        <Redirect to="/" />
      )}
    </Switch>
  )
}

export default MerchantUsers
