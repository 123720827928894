import {
  createCouponPool as createCouponPoolAction,
  GetAllResolvedAction,
  getCouponPools as getCouponPoolsAction,
} from 'actions/couponPools'
import { getRedeemTemplate } from 'actions/redeemTemplates'
import {
  CouponPoolGenerationType,
  CouponPoolType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

type CreatePoolParams = {
  templateId: number
  staticCode?: string
  generationType: CouponPoolGenerationType
  type?: CouponPoolType
  returnExistingPool?: boolean
}
interface Result {
  createCouponPool: (pool: CreatePoolParams) => Promise<CouponPool | undefined>
  getCouponPools: (
    templateId: number,
    generationType?: CouponPoolGenerationType
  ) => Promise<CouponPool[] | undefined>
}

export default function (): Result {
  const dispatch = useDispatch()

  const createCouponPool = useCallback(
    async (params: CreatePoolParams) => {
      const {
        templateId,
        staticCode = '',
        generationType,
        type = CouponPoolType.RAW,
        returnExistingPool = false,
      } = params
      if (!templateId) return

      if (returnExistingPool) {
        const result: GetAllResolvedAction = (await dispatch(
          getCouponPoolsAction(templateId)
        )) as any
        if (
          result.type === 'couponPool/GET_ALL_SUCCESS' &&
          !!result.payload.data.coupon_pools?.length
        ) {
          return result.payload.data.coupon_pools?.[0]
        }
      }

      const response = (await dispatch(
        createCouponPoolAction(templateId, {
          generation_type: generationType,
          type: type,
          static_code: staticCode,
        })
      )) as any

      if (!response.error) {
        await dispatch(getRedeemTemplate(templateId))
        await dispatch(getCouponPoolsAction(templateId))

        return response.payload.data.coupon_pool as CouponPool
      }
      return
    },
    [dispatch]
  )

  const getCouponPools = useCallback(
    async (templateId: number, generationType?: CouponPoolGenerationType) => {
      const result = (await dispatch(getCouponPoolsAction(templateId))) as any
      if (
        result.type === 'couponPool/GET_ALL_SUCCESS' &&
        !!result.payload.data.coupon_pools?.length
      ) {
        const pools = result.payload.data.coupon_pools as CouponPool[]
        if (!!generationType) {
          return pools.filter(cp => cp.generation_type === generationType)
        }
        return pools
      }
    },
    [dispatch]
  )

  return {
    createCouponPool,
    getCouponPools,
  }
}
