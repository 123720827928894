import { Category } from 'models/IntegrationsConfig'
import { buildTranslate } from '../locales'
import { integrationsCenterUrls } from './urlUtils'

const t = buildTranslate('integration_center')

export const integrationsList: Array<Category> = [
  {
    key: 'pos_instore_sku_data',
    title: t('posInstoreSkuData.title'),
    description: null,
    helpUrl: null,
    integrations: [
      {
        description: null,
        title: t('posInstoreSkuData.toast.title'),
        key: 'toast',
        helpUrl: integrationsCenterUrls.posInstoreSkuData.toast,
        emailSubject: t('posInstoreSkuData.emailSubject', {
          pos: t('posInstoreSkuData.toast.title'),
        }),
        partners: [],
      },
      {
        key: 'revel',
        title: t('posInstoreSkuData.revel.title'),
        description: null,
        helpUrl: integrationsCenterUrls.posInstoreSkuData.revel,
        emailSubject: t('posInstoreSkuData.emailSubject', {
          pos: t('posInstoreSkuData.revel.title'),
        }),
        partners: [],
      },
      {
        key: 'olo_omnivore',
        title: t('posInstoreSkuData.oloOmnivore.title'),
        description: t('posInstoreSkuData.oloOmnivore.description'),
        helpUrl: integrationsCenterUrls.posInstoreSkuData.olo_omnivore,
        emailSubject: t('posInstoreSkuData.emailSubject', {
          pos: t('posInstoreSkuData.oloOmnivore.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'pos_instore_offer_management',
    title: t('posInstoreOfferManagement.title'),
    helpUrl: null,
    description: null,
    integrations: [
      {
        key: 'thanx',
        title: t('posInstoreOfferManagement.thanx.title'),
        description: t('posInstoreOfferManagement.thanx.description'),
        helpUrl: null,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'toast',
        title: t('posInstoreOfferManagement.toast.title'),
        description: null,
        helpUrl: integrationsCenterUrls.posInstoreOfferManagement.toast,
        emailSubject: t('posInstoreOfferManagement.emailSubject', {
          pos: t('posInstoreOfferManagement.toast.title'),
        }),
        partners: [],
      },
      {
        key: 'fishbowl',
        title: t('posInstoreOfferManagement.fishbowl.title'),
        description: t('posInstoreOfferManagement.fishbowl.description'),
        helpUrl: integrationsCenterUrls.posInstoreOfferManagement.fishbowl,
        emailSubject: t('posInstoreOfferManagement.emailSubject', {
          pos: t('posInstoreOfferManagement.fishbowl.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'digital_order_management',
    title: t('digitalOrderManagement.title'),
    description: t('digitalOrderManagement.description'),
    helpUrl: null,
    integrations: [
      {
        key: 'olo',
        title: t('digitalOrderManagement.olo.title'),
        description: null,
        helpUrl: integrationsCenterUrls.digital_order_management.olo,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'toast',
        title: t('digitalOrderManagement.toast.title'),
        description: null,
        helpUrl: integrationsCenterUrls.digital_order_management.toast,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'open_tender',
        title: t('digitalOrderManagement.openTender.title'),
        description: null,
        helpUrl: integrationsCenterUrls.digital_order_management.open_tender,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'deliverect',
        title: t('digitalOrderManagement.deliverect.title'),
        description: null,
        helpUrl: integrationsCenterUrls.digital_order_management.deliverect,
        emailSubject: null,
        partners: [],
        comingSoon: true,
      },
    ],
  },
  {
    key: 'delivery',
    title: t('delivery.title'),
    helpUrl: null,
    description: null,
    integrations: [
      {
        key: 'olo_dispatch',
        title: t('delivery.oloDispatch.title'),
        description: t('managed_by_olo'),
        helpUrl: integrationsCenterUrls.delivery.olo_dispatch,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'doordash',
        title: t('delivery.doordash.title'),
        description: t('managed_via_thanx'),
        helpUrl: integrationsCenterUrls.delivery.doordash,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'uber',
        title: t('delivery.uber.title'),
        description: null,
        helpUrl: integrationsCenterUrls.delivery.doordash,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'vromo',
        title: t('delivery.vromo.title'),
        description: null,
        helpUrl: integrationsCenterUrls.delivery.vromo,
        emailSubject: null,
        partners: [],
      },
    ],
  },
  {
    key: 'cdp',
    title: t('cdp.title'),
    description: null,
    helpUrl: null,
    integrations: [
      {
        key: 'olo_gdp',
        title: t('cdp.oloGdp.title'),
        description: null,
        helpUrl: integrationsCenterUrls.cdp.olo_gdp,
        emailSubject: t('cdp.emailSubject', { cdp: t('cdp.oloGdp.title') }),
        partners: [],
      },
      {
        key: 'bikky',
        title: t('cdp.bikky.title'),
        description: null,
        helpUrl: integrationsCenterUrls.cdp.bikky,
        emailSubject: t('cdp.emailSubject', { cdp: t('cdp.bikky.title') }),
        partners: [],
        comingSoon: true,
      },
      {
        key: 'delightable',
        title: t('cdp.delightable.title'),
        description: null,
        helpUrl: integrationsCenterUrls.cdp.delightable,
        emailSubject: t('cdp.emailSubject', {
          cdp: t('cdp.delightable.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'email_marketing',
    title: t('emailMarketing.title'),
    description: t('emailMarketing.description'),
    helpUrl: null,
    integrations: [
      {
        key: 'thanx',
        title: t('emailMarketing.thanx.title'),
        description: t('emailMarketing.thanx.description'),
        helpUrl: integrationsCenterUrls.emailMarketing.thanx,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'klaviyo',
        title: t('emailMarketing.klaviyo.title'),
        description: null,
        helpUrl: integrationsCenterUrls.emailMarketing.klaviyo,
        emailSubject: t('emailMarketing.emailSubject', {
          integration: t('emailMarketing.klaviyo.title'),
        }),
        partners: [],
      },
      {
        key: 'braze',
        title: t('emailMarketing.braze.title'),
        description: null,
        helpUrl: integrationsCenterUrls.emailMarketing.braze,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'olo_gdp',
        title: t('emailMarketing.oloGdp.title'),
        description: null,
        helpUrl: integrationsCenterUrls.emailMarketing.olo_gdp,
        emailSubject: t('emailMarketing.emailSubject', {
          integration: t('emailMarketing.oloGdp.title'),
        }),
        partners: [],
      },
      {
        key: 'fishbowl',
        title: t('emailMarketing.fishbowl.title'),
        description: null,
        helpUrl: integrationsCenterUrls.emailMarketing.fishbowl,
        emailSubject: t('emailMarketing.emailSubject', {
          integration: t('emailMarketing.fishbowl.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'sms_marketing',
    title: t('smsMarketing.title'),
    description: t('smsMarketing.description'),
    helpUrl: null,
    integrations: [
      {
        key: 'thanx',
        title: t('smsMarketing.thanx.title'),
        description: t('smsMarketing.thanx.description'),
        helpUrl: integrationsCenterUrls.smsMarketing.thanx,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'klaviyo',
        title: t('smsMarketing.klaviyo.title'),
        description: null,
        helpUrl: integrationsCenterUrls.smsMarketing.klaviyo,
        emailSubject: t('smsMarketing.emailSubject', {
          integration: t('smsMarketing.klaviyo.title'),
        }),
        partners: [],
        comingSoon: true,
      },
      {
        key: 'attentive',
        title: t('smsMarketing.attentive.title'),
        description: null,
        helpUrl: integrationsCenterUrls.help,
        emailSubject: t('smsMarketing.emailSubject', {
          integration: t('smsMarketing.attentive.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'marketing_automation_wifi',
    title: t('marketingAutomationWifi.title'),
    description: null,
    helpUrl: null,
    integrations: [
      {
        key: 'adentro',
        title: t('marketingAutomationWifi.adentro.title'),
        description: t('marketingAutomationWifi.adentro.description'),
        helpUrl: integrationsCenterUrls.help,
        emailSubject: t('marketingAutomationWifi.emailSubject', {
          integration: t('marketingAutomationWifi.adentro.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'feedback',
    title: t('feedback.title'),
    description: t('feedback.description'),
    helpUrl: integrationsCenterUrls.feedback.thanx,
    integrations: [
      {
        key: 'thanx',
        title: t('feedback.thanx.title'),
        description: t('feedback.thanx.description'),
        helpUrl: integrationsCenterUrls.feedback.thanx,
        emailSubject: t('feedback.emailSubject', {
          integration: t('feedback.thanx.title'),
        }),
        partners: [],
      },
      {
        key: 'tattle',
        title: t('feedback.tattle.title'),
        description: null,
        helpUrl: integrationsCenterUrls.feedback.tattle,
        emailSubject: t('feedback.emailSubject', {
          integration: t('feedback.tattle.title'),
        }),
        partners: [],
      },
      {
        key: 'ovation',
        title: t('feedback.ovation.title'),
        description: null,
        helpUrl: integrationsCenterUrls.feedback.ovation,
        emailSubject: t('feedback.emailSubject', {
          integration: t('feedback.ovation.title'),
        }),
        partners: [],
      },
      {
        key: 'yumpingo',
        title: t('feedback.yumpingo.title'),
        description: null,
        helpUrl: integrationsCenterUrls.help,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'guestxm',
        title: t('feedback.guestxm.title'),
        description: null,
        helpUrl: integrationsCenterUrls.help,
        emailSubject: null,
        partners: [],
      },
      {
        key: 'momos',
        title: t('feedback.momos.title'),
        description: null,
        helpUrl: integrationsCenterUrls.help,
        emailSubject: null,
        partners: [],
      },
    ],
  },
  {
    key: 'custom_data_capture',
    title: t('customDataCapture.title'),
    helpUrl: null,
    description: null,
    integrations: [
      {
        key: 'jotform',
        title: t('customDataCapture.jotform.title'),
        description: null,
        helpUrl: integrationsCenterUrls.help,
        emailSubject: t('customDataCapture.emailSubject', {
          integration: t('customDataCapture.jotform.title'),
        }),
        partners: [],
      },
    ],
  },
  {
    key: 'stored_value',
    title: t('storedValue.title'),
    helpUrl: null,
    description: null,
    integrations: [
      {
        key: 'ansa',
        title: t('storedValue.ansa.title'),
        description: null,
        helpUrl: integrationsCenterUrls.storedValue.ansa,
        emailSubject: null,
        partners: [],
      },
    ],
  },
]
