import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import { getMerchantCouponPools } from 'actions/merchantCouponPools'
import { createRedeemTemplate } from 'actions/redeemTemplates'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import lowerFirst from 'lodash/lowerFirst'
import {
  CouponPoolGenerationType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { generateFormModel, isNDR, mapCouponPoolToSelect } from '../helpers'
import LeavingPageConfirmation from '../LeavingPageConfirmation'
import ConfigurationStep from './ConfigurationStep'
import BonusPointsConfigurationStep from './ConfigurationStep/BonusPoints'
import { FormModel, FormModelProvider } from './FormModel'
import NameStep from './NameStep'
import TypeStep from './TypeStep'
import useAutosave from './useAutosave'
import useCouponPools from './useCouponPools'

export type Step = 'type' | 'name' | 'configuration'

type Props = {
  template?: RedeemTemplate
  couponPools?: CouponPool[] | null
  onSuccessExit: () => void
}

const t = buildTranslate('redeem_manager.builder')

const Builder: React.FC<Props> = ({ template, couponPools, onSuccessExit }) => {
  const merchant = useCurrentMerchant()
  const dispatch = useDispatch()
  const [css] = useStyletron()
  const { getCouponPools } = useCouponPools()

  const isDraft = template ? template.state === 'unpublished' : true
  const [step, setStep] = useState<Step>(isDraft ? 'type' : 'name')
  const [templateId, setTemplateId] = useState<number | null>(
    template?.id ?? null
  )
  const [isValidForm, setIsValidForm] = useState(!isDraft)
  const [isValidCouponPool, setIsValidCouponPool] = useState(!isDraft)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldPreventNavigation, setShouldPreventNavigation] = useState(false)
  const [formModel, setFormModel] = useState<FormModel>(
    generateFormModel(merchant, template, couponPools)
  )

  const { triggerSave, forceSave, isSaving } = useAutosave(templateId)

  function onStepSelected(selection: Step) {
    setStep(selection)
  }

  async function createTemplate() {
    const { name, typeItem } = formModel

    if (!name || !typeItem) return

    setIsLoading(true)
    const result = await dispatch(
      createRedeemTemplate({
        name,
        // select the first possible type for now.
        // and it can be changed on configuration step
        type: typeItem.types[0],
        subtype: typeItem.subtype,
      })
    )
    setIsLoading(false)

    if (result.error) {
      setError(t('error'))
      return
    }

    const newTemplateId = result.payload.data.redeem_template.id
    setTemplateId(newTemplateId)
    setStep('configuration')
  }

  async function onFormChange(newForm: FormModel) {
    if (!templateId) return
    const newCouponCodes = newForm.couponCodes
    let formCouponPools = couponPools
    if (
      !!newCouponCodes?.generationType &&
      formModel?.couponCodes?.generationType !== newCouponCodes.generationType
    ) {
      formCouponPools = await getCouponPools(
        templateId,
        newCouponCodes?.generationType
      )
    }
    let selectedCouponPools: any = !!formCouponPools?.length
      ? formCouponPools
          .filter(cp => cp.generation_type === newCouponCodes?.generationType)
          .map(cp => mapCouponPoolToSelect(cp))
      : []
    let staticCode = ''

    if (
      !!templateId &&
      formModel.typeItem &&
      !isNDR(formModel.typeItem.types[0])
    ) {
      if (
        !newCouponCodes?.generationType ||
        newCouponCodes?.generationType === CouponPoolGenerationType.STATIC ||
        newForm.instoreRedemptionType === 'manager_comp'
      ) {
        selectedCouponPools = []
      } else if (!!newForm.selectedCouponPools) {
        selectedCouponPools =
          newForm.selectedCouponPools?.filter(
            cp =>
              cp.generation_type ===
              (newCouponCodes?.generationType ||
                CouponPoolGenerationType.DYNAMIC)
          ) || [] // only set it if selectedCouponPools is present in the form
      }

      if (newCouponCodes?.generationType === CouponPoolGenerationType.STATIC) {
        staticCode = newForm.couponCodes.staticCode
      }
    }

    const form = {
      ...formModel,
      ...newForm,
      description:
        isDraft || !formModel.availableOnline
          ? lowerFirst(newForm.description)
          : newForm.description,
      couponCodes: {
        ...formModel.couponCodes,
        ...newCouponCodes,
        staticCode,
      },
      images: {
        ...formModel.images,
        ...newForm.images,
      },
      numberOfPoints: newForm.numberOfPoints,
      selectedCouponPools,
    }

    if (template?.type.includes('experience')) {
      if (newForm.images && Object.values(newForm.images).includes(undefined)) {
        const newFormImages = form.images
        Object.keys(form.images).forEach(key => {
          if (newFormImages[key] === undefined)
            newFormImages[key] = formModel.images[key]
        })
        form.images = newFormImages
      }
      form.availableCustomerSupport = false
    }
    setFormModel(form)
    setShouldPreventNavigation(!isDraft)
    if (isDraft) {
      triggerSave(form)
    }

    // if we change the generation type, we should
    // re-fetch available pools
    if (
      template?.id &&
      !isNDR(template.type) &&
      !!newForm.couponCodes &&
      formModel?.couponCodes?.generationType !==
        newForm?.couponCodes?.generationType
    ) {
      await getCouponPools(template.id)
      dispatch(
        getMerchantCouponPools(
          newForm?.couponCodes?.generationType ||
            CouponPoolGenerationType.DYNAMIC
        )
      )
    }
  }

  const isBonusPoints = formModel.typeItem?.key === 'bonus_points'

  return (
    <FormModelProvider value={{ model: formModel, setModel: setFormModel }}>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`${css({
          paddingTop: '80px',
        })} pb-xxxl grey-05-bkg`}
      >
        {step === 'type' && (
          <TypeStep
            templateId={templateId}
            typeItem={formModel.typeItem}
            setRedeemType={newType => {
              let updatedType = {
                // resetting the model as this requires re-entering most of the information
                ...generateFormModel(merchant, template, couponPools),
                name: formModel.name,
                description: formModel.description,
                typeItem: newType,
              }
              if (newType.key === 'golden_ticket')
                updatedType = {
                  ...updatedType,
                  availableInstore: true,
                  availableOnline: false,
                }
              if (
                newType.key === 'golden_ticket' ||
                newType.key === 'hidden_menu'
              ) {
                updatedType = {
                  ...updatedType,
                  availableCustomerSupport: false,
                }
              }
              setFormModel(updatedType)
            }}
            onContinue={() => setStep('name')}
            onStepSelected={onStepSelected}
            merchant={merchant}
          />
        )}
        {step === 'name' && (
          <NameStep
            templateId={templateId}
            name={formModel.name}
            error={error}
            isLoading={isLoading || isSaving}
            onFormChange={({ name }) => {
              setShouldPreventNavigation(!isDraft)
              setFormModel({
                ...formModel,
                name,
              })
            }}
            skipTypeStep={!isDraft}
            onContinue={async () => {
              if (templateId) {
                await forceSave(formModel)
                setShouldPreventNavigation(false)
                setStep('configuration')
              } else {
                await createTemplate()
              }
            }}
            onStepSelected={onStepSelected}
            onSaveAndExit={async () => {
              if (templateId) {
                setShouldPreventNavigation(false)
                await forceSave(formModel)
              } else {
                await createTemplate()
              }
              onSuccessExit()
            }}
          />
        )}
        {/* Non-bonus points reward */}
        {step === 'configuration' && templateId !== null && !isBonusPoints && (
          <ConfigurationStep
            templateId={templateId}
            isLoading={isSaving}
            isDraft={isDraft}
            isValidForm={isValidForm}
            setIsValidForm={setIsValidForm}
            isValidCouponPool={isValidCouponPool}
            setIsValidCouponPool={setIsValidCouponPool}
            onStepSelected={onStepSelected}
            onFormChange={onFormChange}
            onSuccessExit={onSuccessExit}
            saveForm={async () => {
              if (templateId) {
                setShouldPreventNavigation(false)
                await forceSave(formModel)
              }
            }}
          />
        )}
        {/* Bonus points reward */}
        {step === 'configuration' && templateId !== null && isBonusPoints && (
          <BonusPointsConfigurationStep
            templateId={templateId}
            isLoading={isSaving}
            isDraft={isDraft}
            isValidForm={isValidForm}
            setIsValidForm={setIsValidForm}
            onStepSelected={onStepSelected}
            onFormChange={onFormChange}
            onSuccessExit={onSuccessExit}
            saveForm={async () => {
              if (templateId) {
                setShouldPreventNavigation(false)
                await forceSave(formModel)
              }
            }}
          />
        )}
      </div>
      <Switch condition={shouldPreventNavigation}>
        <LeavingPageConfirmation
          isLoading={isSaving}
          onConfirm={async () => {
            if (isValidForm && isValidCouponPool) {
              await forceSave(formModel)
            } else {
              setShouldPreventNavigation(false)
              dispatch(
                alert({
                  key: 'danger',
                  message: t('leaving_page_confirmation.invalid_form'),
                  timeout: 5,
                })
              )
            }
          }}
        />
      </Switch>
    </FormModelProvider>
  )
}

export default Builder
