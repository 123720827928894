import { Switch } from '@thanx/uikit/switch'
import Help from 'components/Help'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { getHelp } from 'scenes/RedeemManager/Builder/ConfigurationStep/helpers'
import Restrictions from 'scenes/RedeemManager/Builder/ConfigurationStep/Restrictions'
import { generateTypeItem } from 'scenes/RedeemManager/helpers'
import CustomerSupportCard from './CustomerSupportCard'
import InstoreRedemptionCard from './InstoreRedemptionCard'
import OnlineRedemptionCard from './OnlineRedemptionCard'
import RewardDetailsCard from './RewardDetailsCard'

type PropsT = {
  template: RedeemTemplate
}

const ConfigurationTab: React.FC<PropsT> = ({ template }) => {
  const typeItem = generateTypeItem(template)
  const helpArticles = getHelp(typeItem?.key)
  return (
    <>
      <RewardDetailsCard template={template} typeItem={typeItem} />
      <Switch condition={typeItem?.key !== 'bonus_points'}>
        <OnlineRedemptionCard template={template} />
        <InstoreRedemptionCard template={template} />
      </Switch>
      <CustomerSupportCard template={template} />
      <Restrictions />
      <Help
        title={I18n.t('redeem_manager.builder.steps.configuration.help.title')}
        articles={helpArticles}
      />
    </>
  )
}

export default ConfigurationTab
