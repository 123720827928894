import { Fields as Link } from 'models/Link'
import { api } from './index'

export type Location = {
  merchant_id: number
  category_id: number
  id: number
  name: string
  street: string
  city: string
  state: string
  zip: string
  latitude: string
  longitude: string
  phone: string
  time_zone: string
  opentable_link?: Link
  website_link?: Link
  visibility: string

  fullName(): string
}

export type GetLocationsParams = {
  coming_soon?: boolean
  full_visibility?: boolean
}

export type UpdateLocationPayload = Partial<Location>

export const locationsApi = api.injectEndpoints({
  endpoints: builder => ({
    getLocations: builder.query<{ locations: Location[] }, GetLocationsParams>({
      query: params => ({
        url: 'locations',
        params,
      }),
      providesTags: ['Location'],
    }),
    updateLocation: builder.mutation<Location, UpdateLocationPayload>({
      query: payload => ({
        url: `locations/${payload.id}`,
        method: 'PATCH',
        body: {
          location: payload,
        },
      }),
      invalidatesTags: ['Location'],
    }),
  }),
})
