import { Text } from '@thanx/uikit/text'
import Badges from 'components/Badges'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { ContentBlockData } from 'scenes/Cms/Content/AppHomePage/hooks'
import ExternalFormContent from './ExternalFormContent'
import FeaturedContent from './FeaturedContent'
import HelperSection from './HelperSection'

const t = buildTranslate('cms.content.app_home_page.content.edit_block.content')

type Props = {
  blockId: string
  contentBlock: ContentBlockData
}

const Content = ({ blockId, contentBlock }: Props) => {
  const badges = [
    t('badges.app_only'),
    t('badges.wide_1800_px'),
    t('badges.any_height'),
    t('badges.jpg'),
    t('badges.png'),
  ]
  const { type } = contentBlock

  const renderContent = () => {
    switch (type) {
      case 'featured_content':
        return <FeaturedContent blockId={blockId} contentBlock={contentBlock} />
      case 'external_form':
        return (
          <ExternalFormContent blockId={blockId} contentBlock={contentBlock} />
        )
      default:
        return <></>
    }
  }

  return (
    <div className="d-flex flex-column">
      <Text.Header3>{t('title')}</Text.Header3>
      <Badges className="mb-m mt-m mb-xl" values={badges} />
      <Section className="flex-1 mb-l mr-xs p-l">{renderContent()}</Section>
      <HelperSection type={contentBlock.type} />
    </div>
  )
}

export default Content
