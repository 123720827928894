import { updateRedeemTemplate } from 'actions/redeemTemplates'
import { getValidation } from 'actions/validations'
import Badges from 'components/Badges'
import ImagePicker from 'components/ImagePicker'
import useDispatch from 'hooks/useDispatch'
import { Fields as Validation } from 'models/Validation'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect } from 'react'
import { useFormModel } from './FormModel'

export type ImageKeys = 'advertising_image' | 'detail_image' | 'index_image'

type Props = {
  badgeValues?: string[]
  className?: string
  templateId: number
  imageValidations: Validation | undefined
  type: ValidationTypes
}

const ImageUploader: React.FC<Props> = ({
  badgeValues,
  className = '',
  imageValidations,
  templateId,
  type,
}) => {
  const { images } = useFormModel().model
  const formModel = images[type]
  const dispatch = useDispatch()

  const onFinalized = async id => {
    if (id) {
      dispatch(
        updateRedeemTemplate(templateId, {
          file_upload: { id },
        })
      )
    }
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(getValidation({ type }))
    }
    fetchData()
  }, [dispatch, type])

  if (!imageValidations) {
    return null
  }

  return (
    <div className={className}>
      <Badges values={badgeValues} />
      <ImagePicker
        imageWidth="478px"
        className="my-xs"
        name={`images.${type}`}
        value={formModel}
        imageValidations={imageValidations}
        onFinalized={onFinalized}
        shouldValidate
      />
    </div>
  )
}

export default ImageUploader
