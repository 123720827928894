import { Text } from '@thanx/uikit/text'
import {
  merchantUsersApi,
  Role,
  UpdateMerchantUserPayload,
} from 'api/merchantUsers'
import Spinner from 'components/Spinner'
import Formsy from 'formsy-react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import FormLayout from './FormLayout'
import MerchantUserForm from './MerchantUserForm'

type Props = {} & RouteComponentProps<{ id: string }>

const EditMerchantUser = ({ match }: Props) => {
  const merchantUserId = match.params.id

  const { data, isLoading } =
    merchantUsersApi.useGetMerchantUserQuery(merchantUserId)
  const [updateMerchantUser] = merchantUsersApi.useUpdateMerchantUserMutation()

  const merchantUser = data?.merchant_user

  const [merchantUserPatch, setMerchantUserPatch] = useState<
    Partial<UpdateMerchantUserPayload>
  >({})

  const [css] = useStyletron()

  useEffect(() => {
    setMerchantUserPatch(prev => ({
      ...prev,
      role: merchantUser?.role,
    }))
  }, [merchantUser])

  return (
    <Spinner isLoading={isLoading}>
      <FormLayout
        isEditing
        onFinish={() => {
          updateMerchantUser({ ...merchantUserPatch, id: +merchantUserId })
        }}
      >
        <Formsy role="form">
          <div className={css({ marginBottom: '40px' })}>
            <Text.Header2>{`${merchantUser?.first_name} ${merchantUser?.last_name}`}</Text.Header2>
            <Text.BodyText3 color="#6A747F">
              {merchantUser?.email}
            </Text.BodyText3>
          </div>
          <MerchantUserForm
            merchantUser={merchantUserPatch}
            onChange={nextMerchantUser => {
              setMerchantUserPatch(prev => ({
                ...prev,
                location_ids: nextMerchantUser.location_ids,
                role: nextMerchantUser.role as Role,
              }))
            }}
          />
        </Formsy>
      </FormLayout>
    </Spinner>
  )
}

export default EditMerchantUser
