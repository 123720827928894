import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { alert } from 'actions/flash'
import AudienceIcon from 'assets/images/campaign-center/builder/img_audience.svg'
import ReviewSection from 'components/review/ReviewSection'
import useDispatch from 'hooks/useDispatch'
import isEmpty from 'lodash/isEmpty'
import type { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectLocation } from 'selectors/location'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import Audience from './components/Audience'
import AudienceBreakoutModal from './components/AudienceBreakoutModal'
import EmptyAudience from './components/EmptyAudience'

type RenderProps = {
  show: boolean
  userCountMetric?: UserCountMetric
  onModalCloseClicked(): void
}

export type LocationRenderProps = {
  isOpen: boolean
  categoryIds?: number[]
  locationIds: number[]
  onEdit?: () => void
  onClose: () => void
}

type Props = {
  campaign: Campaign
  displayLocationDetails?: boolean
  editStep?: string
  isEditable?: boolean
  locationIds?: number[]
  categoryIds?: number[]
  setCurrentStep?: (step: string) => void
  renderAudienceBreakoutModal?: (props: RenderProps) => React.ReactNode
}

const AudienceContainer: React.FC<Props> = props => {
  const {
    campaign,
    displayLocationDetails = false,
    editStep = 'setup',
    isEditable = true,
    locationIds,
    categoryIds,
    setCurrentStep,
    renderAudienceBreakoutModal,
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const [showBreakoutModal, setShowBreakoutModal] = useState(false)
  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric
  )
  const currentMerchantUser = useSelector(selectCurrentMerchantUser)
  const { setConfig } = useCampaignConfig()

  const location = useSelector(state =>
    selectLocation(state, campaign.config_location_id)
  )
  const dispatch = useDispatch()

  const controlPercent = campaign.config_control_percent
  const includeRecentlyRewarded =
    campaign.config_include_recently_rewarded_users

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(
        getCampaignMetricByType(campaign.id, 'user_count')
      )
      setIsLoading(false)

      if (response.error) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
  }, [campaign.id, controlPercent, dispatch, includeRecentlyRewarded])

  function onEditClicked() {
    if (
      //@ts-ignore
      currentMerchantUser.isApproverFor(campaign) ||
      //@ts-ignore
      !currentMerchantUser.canEditAudienceFor(campaign)
    )
      return
    if (!setCurrentStep) return
    setConfig(campaign)
    setCurrentStep(editStep)
  }

  function audienceBreakoutModal() {
    const props = {
      show: showBreakoutModal,
      userCountMetric,
      includeRecentlyRewarded,
      onModalCloseClicked: () => setShowBreakoutModal(false),
    }

    if (!renderAudienceBreakoutModal) {
      return <AudienceBreakoutModal {...props} />
    }

    return renderAudienceBreakoutModal(props)
  }

  let hasNoAudience: boolean
  switch (campaign.type) {
    case 'custom':
    case 'custom_automated':
      hasNoAudience = !campaign.config_target_segment_id
      break
    case 'engage_vip':
    case 'upsell':
      hasNoAudience =
        isEmpty(campaign.config_location_ids) &&
        isEmpty(campaign.config_location_category_ids)
      break
    case 'location_close':
    case 'location_message':
    case 'location_promote':
    case 'location_reopen':
      hasNoAudience =
        isEmpty(campaign.config_target_location_ids) &&
        !campaign.config_location_id &&
        !campaign.config_reopening_at &&
        !campaign.config_closing_at
      break
    case 'message':
      hasNoAudience =
        !campaign.config_target_member && !campaign.config_target_subscriber
      break
    default:
      hasNoAudience = false
  }
  if (hasNoAudience) {
    return <EmptyAudience isEditable={isEditable} onEdit={onEditClicked} />
  }

  const canEdit =
    isEditable &&
    !!setCurrentStep &&
    //@ts-ignore
    !currentMerchantUser.isApproverFor(campaign) &&
    //@ts-ignore
    currentMerchantUser.canEditAudienceFor(campaign)
  return (
    <ReviewSection
      icon={AudienceIcon}
      isEditable={canEdit}
      onEdit={onEditClicked}
    >
      <Audience
        campaign={campaign}
        isDisabled={isLoading}
        isLoading={isLoading}
        customerCount={userCountMetric?.data?.count || 0}
        isExclusionChecked={!includeRecentlyRewarded}
        onLinkClicked={() => setShowBreakoutModal(true)}
        onEdit={onEditClicked}
        categoryIds={categoryIds}
        locationIds={locationIds}
        promotedLocation={location}
        displayLocationDetails={displayLocationDetails}
      />
      {audienceBreakoutModal()}
    </ReviewSection>
  )
}

export default AudienceContainer
