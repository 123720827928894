import { buildTranslate } from 'locales'
import { ValidationTypes } from 'models/Validation'
import React from 'react'
import { useSelector } from 'react-redux'
import ImageUploader from 'scenes/RedeemManager/Builder/ImageUploader'
import { selectRedeemIndexValidation } from 'selectors/validation'

const t = buildTranslate('redeem_manager.builder.steps.configuration.images')

type Props = {
  existingId?: number
  isNDR: boolean
  templateId: number
}

const Image: React.FC<Props> = ({ isNDR, templateId }) => {
  const imageValidations = useSelector(selectRedeemIndexValidation)
  const badgeValues = [t('index_size'), t('jpg'), t('png')]

  if (isNDR) {
    badgeValues.unshift(t('app_only'))
  }
  return (
    <ImageUploader
      className="pl-l w-100 pt-xs"
      badgeValues={badgeValues}
      imageValidations={imageValidations}
      type={ValidationTypes.RedeemIndex}
      templateId={templateId}
    />
  )
}

export default Image
