import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { LinkContainer } from 'react-router-bootstrap'
import { merchantTheme } from 'theme'

type Props = {
  isEditing?: boolean
  canProceed?: boolean
  onFinish?: () => void
  children: React.ReactNode
}

const FormLayout = ({
  isEditing = false,
  onFinish,
  canProceed = true,
  children,
}: Props) => {
  const t = buildTranslate(`merchant_users.${isEditing ? 'edit' : 'new'}`)

  const [css] = useStyletron()

  return (
    <div
      className={`h-100 ${css({
        marginTop: '30px',
        backgroundColor: merchantTheme.colors.grey05,
      })}`}
    >
      <EditNavbar
        title={
          <>
            <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
              {t('subtitle')}
            </Text.SmallCaps3>
            <Text.Header4 tag="div" bold color="white">
              {t('title')}
            </Text.Header4>
          </>
        }
      >
        <div>
          <LinkContainer to="/merchant_users">
            <Button kind={Kind.SECONDARY_REVERSE} className="mr-s">
              {t('cancel')}
            </Button>
          </LinkContainer>
          <Button
            kind={Kind.PRIMARY_REVERSE}
            onClick={onFinish}
            disabled={!canProceed}
          >
            {t('continue')}
          </Button>
        </div>
      </EditNavbar>
      <Container className="h-100">
        <Row>
          <Col className="p-xl">{children}</Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormLayout
